.scheme {
	position: relative;

	&__inner {
		margin: 0 auto;
		width: 640px;
		z-index: 3;
		transition: all 0.6s ease;

		@include pc {
			width: 640 * $pc;
		}

		@include tablet {
			width: 640 * $tablet;
			transition: none;
		}

		.is-pushed-left & {
			transform: translateX(-50%);
		}

		.is-pushed-right & {
			transform: translateX(50%);
		}

		.is-pushed-left &,
		.is-pushed-right & {
			@include tablet {
				left: auto;
				right: auto;
				margin: 0 auto;
				transform: none;
				transition: none;
			}
		}
	}

	&__popups {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}

	&__object {
		position: relative;
		margin: 0 auto;
		width: 156px;
		height: 766px;
		background: url('../images/human.jpg') 0 0 no-repeat;

		@include mobile {
			position: static;
			width: 240px;
			height: 500px;
			background-size: contain;
			background-position-x: center;
		}
	}

	&__helper {
		position: absolute;
		z-index: 100;
		display: none;

		&.is-num-10 {
			display: block;
			top: 30px;
			transform: translateX(calc(-100% + 30px));

			@include mobile {
				top: 20px;
			}
		}

		&.is-num-20 {
			display: block;
			top: 190px;
			transform: translateX(calc(-100% + 25px));

			@include mobile {
				top: 120px;
			}
		}

		&.is-num-30 {
			display: block;
			top: 380px;
			transform: translateX(calc(-100% + 25px));
		}

		&.is-num-50 {
			display: block;
			top: 550px;
			transform: translateX(calc(-100% + 50px));

			@include mobile {
				top: 345px;
			}
		}

		&.is-num-60 {
			display: block;
			top: 730px;
			transform: translateX(calc(-100% + 60px));

			@include mobile {
				top: 480px;
			}
		}
		// БЛОКИ СПРАВА ОТ ЧЕЛОВЕКА
		&.is-num-40 {
			display: block;
			top: 295px;
			transform: translateX(100%) translateX(-15px);

			@include mobile {
				top: 200px;
			}
		}

		&.is-num-70 {
			display: block;
			top: 85px;
			transform: translateX(100%) translateX(-15px);
		
			@include mobile {
				top: 60px;
			}
		}

		&.is-num-80 {
			display: block;
			top: 135px;
			transform: translateX(100%) translateX(0px);
		}

		&.is-num-90 {
			display: block;
			top: 185px;
			transform: translateX(100%) translateX(20px);
		}

		&.is-num-100 {
			display: block;
			top: 260px;
			transform: translateX(100%) translateX(-10px);
		}

		&.is-num-110 {
			display: block;
			top: 195px;
			transform: translateX(100%) translateX(10px);
			
			@include mobile {
				top: 130px;
			}
		}

		&.is-num-120 {
			display: block;
			top: 330px;
			transform: translateX(100%) translateX(-10px);
		}

		&.is-num-130 {
			display: block;
			top: 670px;
			transform: translateX(100%) translateX(0px);
		}

		&.is-num-140 {
			display: block;
			top: 730px;
			transform: translateX(100%) translateX(5px);
		}

		@include mobile {
			left: 0;
			transform: translateX(0) !important;
		}

		&.is-right {
			left: auto;
			right: 0;

			@include mobile {
				left: auto;
				transform: translateX(0) !important;
			}
		}
	}
}
