.countries {

	&__title {
		margin: 0 0 20px;
		font-weight: 400;
		font-size: 30px;
		letter-spacing: 0.03px;
		line-height: 30px;
		text-transform: uppercase;
		text-align: center;
		color: $color-white;

		@include pc {
			margin: 0 0 20 * $pc;
			font-size: 30 * $pc;
			letter-spacing: 0.03 * $pc;
			line-height: 30 * $pc;
		}

		@include tablet {
			margin: 0 0 20 * $tablet;
			font-size: 30 * $tablet;
			letter-spacing: 0.03 * $tablet;
			line-height: 30 * $tablet;
		}

		@include mobile {
			margin: 0 0 20 * $mobile;
			font-size: 30 * $mobile;
			letter-spacing: 0.03 * $mobile;
			line-height: 30 * $mobile;
		}

		span {
			font-weight: 700;
			color: $color-inch-worm;
		}
	}

	&__description {
		margin: 0 0 60px;
		font-weight: 400;
		font-size: 14px;
		letter-spacing: 0.01px;
		line-height: 20px;
		text-align: center;
		color: $color-white;

		@include pc {
			margin: 0 0 60 * $pc;
			font-size: 14 * $pc;
			letter-spacing: 0.01 * $pc;
			line-height: 20 * $pc;
		}

		@include tablet {
			margin: 0 0 60 * $tablet;
			font-size: 14 * $tablet;
			letter-spacing: 0.01 * $tablet;
			line-height: 20 * $tablet;
		}

		@include mobile {
			margin: 0 0 60 * $mobile;
			font-size: 14 * $mobile;
			letter-spacing: 0.01 * $mobile;
			line-height: 20 * $mobile;
		}

		span {
			font-weight: 700;
		}
	}

	&__list {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		display: flex;
		justify-content: center;
		margin: 0 auto 20px;
		width: 50%;

		@include tablet {
			width: 100%;
		}
	}

	&__link {
		min-width: 240px;
	}
}
