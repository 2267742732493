.contacts {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding: 0 60px;

	@include pc {
		padding: 0 60 * $pc;
	}

	@include tablet {
		padding: 0 60 * $tablet;
	}

	@include mobile {
		flex-direction: column;
	}

	&__slider {
		position: relative;
		opacity: 0;
		height: 0;
		transition: opacity 300ms linear;

		&.slick-initialized {
			height: auto;
			opacity: 1;
		}

		&__controls {
			position: absolute;
			right: 0;
			top: -20px;
			z-index: 100;
			display: flex;
			justify-content: space-between;
			width: 50px;
			transform: translateY(-100%);

			@include pc {
				top: -20 * $pc;
				width: 50 * $pc;
			}

			@include tablet {
				top: -20 * $tablet;
				width: 50 * $tablet;
			}

			@include mobile {
				top: -20 * $mobile;
				width: 50 * $mobile;
			}
		}

		&__prev,
		&__next {
			display: block;
			width: 16px;
			height: 16px;
			cursor: pointer;

			@include pc {
				width: 16 * $pc;
				height: 16 * $pc;
			}

			@include tablet {
				width: 16 * $tablet;
				height: 16 * $tablet;
			}

			@include mobile {
				width: 16 * $mobile;
				height: 16 * $mobile;
			}

			svg {
				width: 16px;
				height: 16px;
				transition: opacity 0.3s linear;

				@include pc {
					width: 16 * $pc;
					height: 16 * $pc;
				}

				@include tablet {
					width: 16 * $tablet;
					height: 16 * $tablet;
				}

				@include mobile {
					width: 16 * $mobile;
					height: 16 * $mobile;
				}

				&:hover {
					opacity: 0.75;
				}
			}
		}
	}

	&__left {
		width: percentage(270 / 1035);

		@include tablet {
			display: none;
		}
	}

	&__center {
		width: percentage(420 / 1035);

		@include tablet {
			width: percentage(55 / 100);
		}

		@include mobile {
			width: 100%;
			margin-bottom: 30 * $mobile;
		}
	}

	&__right {
		position: relative;
		align-self: flex-start;
		margin-top: 30px;
		padding: 25px 30px 0;
		width: percentage(270 / 1035);
		background-color: $color-mystic;

		@include pc {
			margin-top: 30 * $pc;
			padding: 25 * $pc 30 * $pc 0;
		}

		@include tablet {
			align-self: flex-end;
			margin-top: 30 * $tablet;
			padding: 25 * $tablet 30 * $tablet 0;
			width: percentage(40 / 100);
		}

		@include mobile {
			margin-top: 30 * $mobile;
			padding: 25 * $mobile 30 * $mobile 0;
			width: 100%;
		}

		&__action {
			margin: 25px -30px 0;
			padding: 25px 0 0;
			background-color: $color-athens-gray;

			.btn {
				width: 100%;
			}

			@include pc {
				margin: 25 * $pc -30 * $pc 0;
				padding: 25 * $pc 0 * $pc 0;
			}

			@include tablet {
				margin: 25 * $tablet -30 * $tablet 0;
				padding: 25 * $tablet 0 * $tablet 0;
			}

			@include mobile {
				margin: 25 * $mobile -30 * $mobile 0;
				padding: 25 * $mobile 0 * $mobile 0;
			}
		}
	}

	&__title {
		margin: 40px 0;
		font-weight: 300;
		font-size: 25px;
		line-height: 25px;
		color: $color-mine-shaft;

		@include pc {
			margin: 40 * $pc 0;
			font-size: 25 * $pc;
			line-height: 25 * $pc;
		}

		@include tablet {
			margin: 40 * $tablet 0;
			font-size: 25 * $tablet;
			line-height: 25 * $tablet;
		}

		@include mobile {
			margin: 20 * $mobile 0;
			font-size: 25 * $mobile;
			line-height: 25 * $mobile;
		}

		span {
			font-weight: 700;
		}
	}

	&__photo {
		position: relative;
		display: block !important;

		img {
			display: block;
			width: 100%;
		}

		&__caption {
			position: absolute;
			left: 0;
			bottom: 0;
			padding: 13px 26px;
			height: 37px;
			background-color: $color-athens-gray;
			font-weight: 400;
			font-size: 11px;
			letter-spacing: 0.03px;
			line-height: 13px;
			text-transform: uppercase;
			color: $color-mine-shaft;

			@include pc {
				padding: 13 * $pc 26 * $pc;
				height: 37 * $pc;
				font-size: 11 * $pc;
				letter-spacing: 0.03 * $pc;
				line-height: 13 * $pc;
			}

			@include tablet {
				padding: 13 * $tablet 26 * $tablet;
				height: 37 * $tablet;
				font-size: 11 * $tablet;
				letter-spacing: 0.03 * $tablet;
				line-height: 13 * $tablet;
			}

			@include mobile {
				padding: 13 * $mobile 26 * $mobile;
				height: 37 * $mobile;
				font-size: 11 * $mobile;
				letter-spacing: 0.03 * $mobile;
				line-height: 13 * $mobile;
			}

			&.is-tiny {
				padding: 8px 12px;
				height: 26px;
				font-size: 8px;
				letter-spacing: 0;
				line-height: 10px;

				@include pc {
					padding: 8 * $pc 12 * $pc;
					height: 26 * $pc;
					font-size: 8 * $pc;
					line-height: 10 * $pc;
				}

				@include tablet {
					padding: 8 * $tablet 12 * $tablet;
					height: 26 * $tablet;
					font-size: 8 * $tablet;
					line-height: 10 * $tablet;
				}

				@include mobile {
					padding: 8 * $mobile 12 * $mobile;
					height: 26 * $mobile;
					font-size: 8 * $mobile;
					line-height: 10 * $mobile;
				}
			}
		}
	}

	&__address,
	&__phone {
		font-weight: 400;
		font-size: 11px;
		letter-spacing: 0.03px;
		line-height: 18px;
		text-transform: uppercase;
		color: $color-mine-shaft;

		@include pc {
			font-size: 11 * $pc;
			letter-spacing: 0.03 * $pc;
			line-height: 18 * $pc;
		}

		@include tablet {
			font-size: 11 * $tablet;
			letter-spacing: 0.03 * $tablet;
			line-height: 18 * $tablet;
		}

		@include mobile {
			font-size: 11 * $mobile;
			letter-spacing: 0.03 * $mobile;
			line-height: 18 * $mobile;
		}
	}

	&__schedule {
		padding-top: 20px;

		@include pc {
			padding-top: 20 * $pc;
		}

		@include tablet {
			padding-top: 20 * $tablet;
		}

		@include mobile {
			padding-top: 20 * $mobile;
		}
	}
}
