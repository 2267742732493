.footer {
	background: $color-athens-gray;

	&__inner {
		display: flex;
		align-items: center;
		margin: 0 auto;
		padding: 100px $gutter-md * 2 90px;
		max-width: $container-md;

		@include pc {
			padding: 70 * $pc $gutter-lg * 2 * $pc 60 * $pc;
			max-width: $container-lg;
		}

		@include tablet {
			padding: 70 * $tablet $gutter-lg * 2 * $tablet 60 * $tablet;
			max-width: $container-lg;
		}

		@include mobile {
			flex-wrap: wrap;
			padding: 0 * $mobile 0 10 * $mobile;
			max-width: $container-lg;
		}
	}

	&__menus {
		display: flex;
		justify-content: space-between;
		width: 45%;

		@include tablet {
			width: 38%;
		}

		@include mobile {
			flex-direction: column;
			margin: 0 0 10 *$mobile;
			width: 100%;
		}
	}

	&__social {
		margin: 0 auto;

		@include mobile {
			display: flex;
			justify-content: center;
			margin: 0;
			width: 50%;
		}
	}

	&__company {
		margin-left: auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@include tablet {
			margin-left: 0;
		}

		@include mobile {
			width: 50%;
			align-self: center;
			margin: 0 0 20 *$mobile;

			.logo {
				display: none;
			}
		}
	}

	&__action {
		display: none;

		@include mobile {
			display: block;
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 800;
			padding: 10 * $mobile 20 * $mobile;
			background: $color-white;

			.btn {
				display: block;
			}
		}
	}
}