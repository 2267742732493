.phone {
	display: flex;
	align-items: center;
	text-decoration: none;

	&__icon {
		margin-right: 12px;

		@include pc {
			margin-right: 12 * $pc;
		}

		@include tablet {
			margin-right: 12 * $tablet;
		}

		@include mobile {
			margin-right: 12 * $mobile;
		}

		svg {
			width: 12px;
			height: 12px;
			fill: #8bdc29;

			@include pc {
				width: 12 * $pc;
				height: 12 * $pc;
			}

			@include tablet {
				width: 12 * $tablet;
				height: 12 * $tablet;
			}

			@include mobile {
				width: 12 * $mobile;
				height: 12 * $mobile;
			}
		}
	}

	&__text {
		margin-right: 5px;
		color: $color-madison-2;

		@include pc {
			margin-right: 5 * $pc;
		}

		@include tablet {
			margin-right: 5 * $tablet;
		}

		@include mobile {
			margin-right: 5 * $mobile;
		}
	}

	&__number {
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.28px;
		line-height: 18px;
		color: $color-madison-2;

		@include pc {
			font-size: 13 * $pc;
			letter-spacing: 0.28 * $pc;
			line-height: 18 * $pc;
		}

		@include tablet {
			font-size: 13 * $tablet;
			letter-spacing: 0.28 * $tablet;
			line-height: 18 * $tablet;
		}

		@include mobile {
			font-size: 13 * $mobile;
			letter-spacing: 0.28 * $mobile;
			line-height: 18 * $mobile;
		}

		.is-light & {
			color: $color-white;
		}
	}

	&.in-footer {
		margin-top: 20px;

		@include pc {
			margin-top: 20 * $pc;
		}

		@include tablet {
			margin-top: 20 * $tablet;
		}

		@include mobile {
			margin-top: 20 * $mobile;
		}

		.phone {
			&__text,
			&__number {
				font-weight: 300;
				font-size: 12px;
				letter-spacing: 0.01px;
				line-height: 16px;

				@include pc {
					font-size: 12 * $pc;
					letter-spacing: 0.01 * $pc;
					line-height: 16 * $pc;
				}

				@include tablet {
					font-size: 12 * $tablet;
					letter-spacing: 0.01 * $tablet;
					line-height: 16 * $tablet;
				}

				@include mobile {
					font-size: 12 * $mobile;
					letter-spacing: 0.01 * $mobile;
					line-height: 16 * $mobile;
				}
			}

			&__text {
				font-weight: 300;
				font-size: 10px;
				letter-spacing: 0.01px;
				line-height: 12px;
				text-transform: lowercase;

				@include pc {
					font-size: 14 * $pc;
					letter-spacing: 0.01 * $pc;
					line-height: 16 * $pc;
				}

				@include tablet {
					font-size: 16 * $tablet;
					letter-spacing: 0.01 * $tablet;
					line-height: 18 * $tablet;
				}

				@include mobile {
					font-size: 14 * $mobile;
					letter-spacing: 0.01 * $mobile;
					line-height: 16 * $mobile;
				}
			}

			&__icon {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 12px;
				width: 28px;
				height: 28px;
				border: 2px solid $color-inch-worm;

				@include mobile {
					width: 41 * $mobile;
					height: 41 * $mobile;
					border: 2 * $mobile solid $color-inch-worm;
				}

				svg {
					width: 14px;
					height: 14px;
					fill: $color-inch-worm;

					@include mobile {
						width: 20 * $mobile;
						height: 20 * $mobile;
					}
				}
			}
		}
	}
}