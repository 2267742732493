.hero {
	position: relative;
	left: 0;
	right: 0;
	top: 0;
	display: flex;
	align-items: center;
	padding-top: 98px;
	min-height: 100vh;
	background-color: $color-madison-1;

	@include pc {
		padding-top: 80 / 760 * 100vh;
	}

	@include tablet {
		padding-top: 80 * $tablet;
	}

	@include mobile {
		min-height: 100vh;
		padding-top: 80 * $mobile;
	}

	&::before {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		width: percentage(435 / 1250);
		height: 100%;
		background-color: $color-white;
		transition: opacity 0.5s ease;

		@include mobile {
			display: none;
		}
	}

	&__inner {
		display: flex;
		align-items: flex-start;
		position: relative;
		margin: 0 auto;
		padding: 2% $gutter-md 0;
		max-width: $container-md;
		width: 100%;
		height: 100%;

		@include pc {
			padding: 2% $gutter-lg * $pc 0;
			max-width: $container-lg;
		}

		@include tablet {
			padding: 2% $gutter-lg * $tablet 0;
			max-width: $container-lg;
		}

		@include mobile {
			padding: 2% 20 * $mobile 0;
			max-width: 100%;
		}
	}

	&__nav {
		position: absolute;
		left: $gutter-md;
		right: $gutter-md;
		bottom: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include pc {
			left: $gutter-lg * $pc;
			right: $gutter-lg * $pc;
			bottom: 20 * $pc;
		}

		@include tablet {
			left: $gutter-lg * $tablet;
			right: $gutter-lg * $tablet;
			bottom: 20 * $tablet;
		}

		@include mobile {
			.nav {
				display: none;
			}
		}
	}

	&__action {
		max-width: 245px;
		margin-right: 5%;
		
		@include pc {
			max-width: 245 * $pc;
		}
		
		@include tablet {
			max-width: 245 * $tablet;
		}

		@include mobile {
			max-width: 245 * $mobile;
			margin: 0 auto 30 * $mobile;
		}

		& > div {
			opacity: 0;
			height: 0;
			transition: opacity 300ms linear;

			&.slick-initialized {
				height: auto;
				opacity: 1;
			}
		}
	}
}