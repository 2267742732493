.social {
	display: flex;

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
		width: 41px;
		height: 41px;
		border: 2px solid $color-madison-1;
		transition: border-color 0.3s ease;

		@include pc {
			margin-right: 10 * $pc;
			width: 41 * $pc;
			height: 41 * $pc;
			border: 2 * $pc solid $color-madison-1;
		}

		@include tablet {
			margin-right: 10 * $tablet;
			width: 41 * $tablet;
			height: 41 * $tablet;
			border: 2 * $tablet solid $color-madison-1;
		}

		@include mobile {
			margin-right: 10 * $mobile;
			width: 41 * $mobile;
			height: 41 * $mobile;
			border: 2 * $mobile solid $color-madison-1;
		}

		&:last-child {
			margin: 0;
		}

		svg {
			fill: $color-madison-1;
			transition: fill 0.3s ease;
		}

		&.is-facebook svg {
			width: 16px;
			height: 25px;

			@include pc {
				width: 16 * $pc;
				height: 25 * $pc;
			}

			@include tablet {
				width: 16 * $tablet;
				height: 25 * $tablet;
			}

			@include mobile {
				width: 16 * $mobile;
				height: 25 * $mobile;
			}
		}

		&.is-instagram svg {
			width: 22px;
			height: 22px;

			@include pc {
				width: 22 * $pc;
				height: 22 * $pc;
			}

			@include tablet {
				width: 22 * $tablet;
				height: 22 * $tablet;
			}

			@include mobile {
				width: 22 * $mobile;
				height: 22 * $mobile;
			}
		}

		&.is-youtube svg {
			width: 20px;
			height: 25px;

			@include pc {
				width: 20 * $pc;
				height: 25 * $pc;
			}

			@include tablet {
				width: 20 * $tablet;
				height: 25 * $tablet;
			}

			@include mobile {
				width: 20 * $mobile;
				height: 25 * $mobile;
			}
		}

		&:hover {
			border-color: $color-inch-worm;

			svg {
				fill: $color-inch-worm;
			}
		}
	}
}