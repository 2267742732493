.helper-popup {
	opacity: 0;
	position: absolute;
	right: 0;
	top: 50%;
	z-index: -100;
	width: 482px;
	transform: translateY(-50%);
	transition: z-index 0s step-end, opacity 0s ease, top 0.3s ease, transform 0.3s ease;
	backface-visibility: hidden;

	@include pc {
		width: 482 * $pc;
	}

	@include tablet {
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 482 * $tablet;
	}
	@include mobile {
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 100%;
	}

	&.is-active {
		opacity: 1;
		z-index: 300;
		transition: z-index 0.6s step-start, opacity 0.6s ease, top 0.3s linear, transform 0.3s linear;
	}

	.is-scrolled-down & {
		top: 100%;
		transform: translateY(-100%) translateY(-50px);
		transition: z-index 0.6s step-start, opacity 0.6s ease, top 0.6s linear, transform 0.6s linear;
	}

	.is-scrolled-up & {
		top: 0;
		transform: translateY(0);
		transition: z-index 0.6s step-start, opacity 0.6s ease, top 0.6s linear, transform 0.6s linear;
	}

	.is-pushed-right & {
		right: auto;
		left: 0;

		@include tablet {
			left: 0;
			right: 0;
			margin: 0 auto;
		}
	}

	.is-num-5 & {
		top: -200px;

		@include pc {
			top: -200 * $pc;
		}

		@include tablet {
			top: -200 * $tablet;
		}
	}

	&__close {
		position: absolute;
		right: 30px;
		top: 20px;
		z-index: 100;
		width: 16px;
		height: 16px;
		cursor: pointer;

		@include pc {
			right: 30 * $pc;
			top: 20 * $pc;
			width: 16 * $pc;
			height: 16 * $pc;
		}

		@include tablet {
			right: 30 * $tablet;
			top: 20 * $tablet;
			width: 16 * $tablet;
			height: 16 * $tablet;
		}

		@include mobile {
			right: 30 * $mobile;
			top: 20 * $mobile;
			width: 16 * $mobile;
			height: 16 * $mobile;
		}

		svg {
			width: 16px;
			height: 16px;
			fill: rgba($color-white, 0.2);
			transition: fill 300ms linear;

			@include pc {
				width: 16 * $pc;
				height: 16 * $pc;
			}

			@include tablet {
				width: 16 * $tablet;
				height: 16 * $tablet;
			}

			@include mobile {
				width: 16 * $mobile;
				height: 16 * $mobile;
			}
		}

		&:hover {
			svg {
				fill: $color-white;
			}
		}
	}

	&__content {
		overflow: hidden;
		position: relative;
		padding: 30px 40px 20px 90px;
		width: 482px;
		height: 282px;
		background-color: $color-blue-zodiac-2;

		@include pc {
			padding: 30 * $pc 40 * $pc 20 * $pc 90 * $pc;
			width: 482 * $pc;
			height: 282 * $pc;
		}

		@include tablet {
			padding: 30 * $tablet 40 * $tablet 20 * $tablet 90 * $tablet;
			width: 482 * $tablet;
			height: 282 * $tablet;
		}

		@include mobile {
			padding: 30 * $mobile 20 * $mobile 10 * $mobile 70 * $mobile;
			width: 100%;
			height: 320 * $mobile;
		}
	}

	&__doctor {
		padding: 24px 0 12px;

		@include pc {
			padding: 24 * $pc 0 12 * $pc;
		}

		@include tablet {
			padding: 24 * $tablet 0 12 * $tablet;
		}

		@include mobile {
			padding: 24 * $mobile 0 12 * $mobile 8 * $mobile;
			background: rgba($color-blue-zodiac-2, 0.7);
		}
	}

	&__header {
		display: flex;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 20;
		width: 282px;
		font-weight: 400;
		font-size: 14px;
		letter-spacing: 0.07px;
		line-height: 21px;
		transform: rotate(270deg) translate(-51px);
		transform-origin: top left;

		@include pc {
			width: 282 * $pc;
			font-size: 14 * $pc;
			letter-spacing: 0.07 * $pc;
			line-height: 21 * $pc;
			transform: rotate(270deg) translate(-51 * $pc);
		}

		@include tablet {
			width: 282 * $tablet;
			font-size: 14 * $tablet;
			letter-spacing: 0.07 * $tablet;
			line-height: 21 * $tablet;
			transform: rotate(270deg) translate(-51 * $tablet);
		}

		@include mobile {
			width: 320 * $mobile;
			font-size: 14 * $mobile;
			letter-spacing: 0.07 * $mobile;
			line-height: 21 * $mobile;
			transform: rotate(270deg) translate(-51 * $mobile);
		}

		&__symptoms {
			flex-grow: 1;
			padding: 15px;
			background-color: $color-atlantis;
			color: rgba($color-black, 0.9);
			text-align: center;

			@include pc {
				padding: 15 * $pc;
			}

			@include tablet {
				padding: 15 * $tablet;
			}

			@include mobile {
				padding: 15 * $mobile;
			}
		}

		&__problem {
			padding: 15px 10px;
			background-color: $color-torch-red;
			color: rgba($color-white, 0.9);

			@include pc {
				padding: 15 * $pc 10 * $pc;
			}

			@include tablet {
				padding: 15 * $tablet 10 * $tablet;
			}

			@include mobile {
				padding: 15 * $mobile 10 * $mobile;
			}
		}
	}

	&__title {
		margin: 0 0 30px;
		font-weight: 700;
		font-size: 20px;
		letter-spacing: 0.12px;
		line-height: 25px;
		color: rgba($color-white, 0.9);

		@include pc {
			margin: 0 0 30 * $pc;
			font-size: 20 * $pc;
			letter-spacing: 0.12 * $pc;
			line-height: 25 * $pc;
		}

		@include tablet {
			margin: 0 0 30 * $tablet;
			font-size: 20 * $tablet;
			letter-spacing: 0.12 * $tablet;
			line-height: 25 * $tablet;
		}

		@include mobile {
			margin: 0 0 30 * $mobile;
			font-size: 20 * $mobile;
			letter-spacing: 0.12 * $mobile;
			line-height: 25 * $mobile;
		}
	}

	&__text {
		margin: 0 0 20px;
		font-weight: 300;
		font-size: 12px;
		letter-spacing: 0.07px;
		line-height: 23px;
		color: rgba($color-white, 0.9);

		@include pc {
			margin: 0 0 20 * $pc;
			font-size: 12 * $pc;
			letter-spacing: 0.07 * $pc;
			line-height: 23 * $pc;
		}

		@include tablet {
			margin: 0 0 20 * $tablet;
			font-size: 12 * $tablet;
			letter-spacing: 0.07 * $tablet;
			line-height: 23 * $tablet;
		}

		@include mobile {
			margin: 0 0 20 * $mobile;
			font-size: 12 * $mobile;
			letter-spacing: 0.07 * $mobile;
			line-height: 23 * $mobile;
		}
	}

	&__action {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 1;
		padding: 10px 0 20px 90px;
		width: 100%;
		background-color: $color-blue-zodiac-2;

		@include pc {
			padding: 10 * $pc 0 20 * $pc 90 * $pc;
		}

		@include tablet {
			padding: 10 * $tablet 0 20 * $tablet 90 * $tablet;
		}

		@include mobile {
			padding: 10 * $mobile 0 20 * $mobile 70 * $mobile;
		}

		&__link {
			display: flex;
			align-items: center;
			font-weight: 400;
			font-size: 12px;
			letter-spacing: 1.44px;
			line-height: 10px;
			text-transform: uppercase;
			text-decoration: none;
			color: $color-white;

			@include pc {
				font-size: 12 * $pc;
				letter-spacing: 1.44 * $pc;
				line-height: 10 * $pc;
			}

			@include tablet {
				font-size: 12 * $tablet;
				letter-spacing: 1.44 * $tablet;
				line-height: 10 * $tablet;
			}

			@include mobile {
				font-size: 12 * $mobile;
				letter-spacing: 1.44 * $mobile;
				line-height: 10 * $mobile;
			}

			&__icon {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 12px;
				border-radius: 50%;
				width: 21px;
				height: 21px;
				background-color: rgba($color-black, 0.2);

				@include pc {
					margin-left: 12 * $pc;
					width: 21 * $pc;
					height: 21 * $pc;
				}

				@include tablet {
					margin-left: 12 * $tablet;
					width: 21 * $tablet;
					height: 21 * $tablet;
				}

				@include mobile {
					margin-left: 12 * $mobile;
					width: 21 * $mobile;
					height: 21 * $mobile;
				}

				svg {
					width: 7px;
					height: 6px;
					fill: $color-white;

					@include pc {
						width: 7 * $pc;
						height: 6 * $pc;
					}

					@include tablet {
						width: 7 * $tablet;
						height: 6 * $tablet;
					}

					@include mobile {
						width: 7 * $mobile;
						height: 6 * $mobile;
					}
				}
			}
		}
	}
}