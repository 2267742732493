/*
 * CSS Styles that are needed by jScrollPane for it to operate correctly.
 *
 * Include this stylesheet in your site or copy and paste the styles below into your stylesheet - jScrollPane
 * may not operate correctly without them.
 */

 .jspContainer {
	overflow: hidden;
	position: relative;
	box-shadow: 0 3px 8px rgba(0,0,0,.2);
	border-radius: 4px;
	background-color: #fff
}

.jspPane {
	position: absolute;
	transition: top 0.2s linear;
}

.jspVerticalBar {
	position: absolute;
	top: 0;
	right: 0;
	width: 16px;
	height: 100%;
	background: red
}

.jspHorizontalBar {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 16px;
	background: red
}

.jspCap {
	display: none
}

.jspHorizontalBar .jspCap {
	float: left
}

.jspTrack {
	background: #fff;
	position: relative
}

.jspDrag {
	border-radius: 2px;
	position: relative;
	top: 0;
	left: 6px;
	width: 4px;
	background-color: rgba($color-black, 0.2);
	cursor: pointer;
	transition: background-color 0.3s linear,
							top 0.2s linear;
	
	&:hover {
		background-color: rgba($color-black, 0.5);
	}
}

.jspHorizontalBar .jspDrag,.jspHorizontalBar .jspTrack {
	float: left;
	height: 100%
}

.jspArrow {
	background: #50506d;
	text-indent: -20000px;
	display: block;
	cursor: pointer;
	padding: 0;
	margin: 0
}

.jspArrow.jspDisabled {
	cursor: default;
	background: #80808d
}

.jspVerticalBar .jspArrow {
	height: 16px
}

.jspHorizontalBar .jspArrow {
	width: 16px;
	float: left;
	height: 100%
}

.jspVerticalBar .jspArrow:focus {
	outline: none
}

.jspCorner {
	background: #eeeef4;
	float: left;
	height: 100%
}
