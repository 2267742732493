// stylelint-disable no-empty-source
// @font-face {
// 	src: url("../fonts/ProximaNova/Thin/ProximaNova-Thin.woff") format("woff");
// 	font-family: "Proxima Nova";
// 	font-weight: 100;
// 	font-style: normal;
// 	font-display: auto;
// }

@font-face {
	src: url("../fonts/ProximaNova/Light/ProximaNova-Light.woff") format("woff");
	font-family: "Proxima Nova";
	font-weight: 300;
	font-style: normal;
	font-display: auto;
}

@font-face {
	src: url("../fonts/ProximaNova/Regular/ProximaNova-Regular.woff") format("woff");
	font-family: "Proxima Nova";
	font-weight: 400;
	font-style: normal;
	font-display: auto;
}

@font-face {
	src: url("../fonts/ProximaNova/Semibold/ProximaNova-Semibold.woff") format("woff");
	font-family: "Proxima Nova";
	font-weight: 600;
	font-style: normal;
	font-display: auto;
}

@font-face {
	src: url("../fonts/ProximaNova/Bold/ProximaNova-Bold.woff") format("woff");
	font-family: "Proxima Nova";
	font-weight: 700;
	font-style: normal;
	font-display: auto;
}

@font-face {
	src: url("../fonts/ProximaNova/ExtraBold/ProximaNova-Extrabld.woff") format("woff");
	font-family: "Proxima Nova";
	font-weight: 800;
	font-style: normal;
	font-display: auto;
}

@font-face {
	src: url("../fonts/ProximaNova/Black/ProximaNova-Black.woff") format("woff");
	font-family: "Proxima Nova";
	font-weight: 900;
	font-style: normal;
	font-display: auto;
}