.carousel {
	&__inner {
		display: flex;
		align-items: flex-start;
		width: 450px;

		@include pc {
			width: 450 * $pc;
		}

		@include tablet {
			width: 450 * $tablet;
		}

		@include mobile {
			width: 360 * $mobile;
		}
	}

	&__photo {
		flex-shrink: 0;
		width: 200px;
		height: 240px;
		padding-left: 20px;

		@include pc {
			width: 200 * $pc;
			height: 240 * $pc;
			padding-left: 20 * $pc;
		}

		@include tablet {
			width: 160 * $tablet;
			height: 200 * $tablet;
			padding-left: 20 * $tablet;
		}

		@include mobile {
			width: 160 * $mobile;
			height: 200 * $mobile;
			padding-left: 20 * $mobile;
		}

		img {
			display: block;
			width: 100%;
			height: 240px;
			object-fit: cover;
			font-family: 'object-fit: cover;';

			@include pc {
				height: 240 * $pc;
			}
	
			@include tablet {
				height: 200 * $tablet;
			}
	
			@include mobile {
				height: 200 * $mobile;
			}
		}
	}

	&__content {
		padding: 5px 25px;
		max-width: 280px;

		@include pc {
			padding: 5 * $pc 25 * $pc;
			max-width: 280 * $pc;
		}

		@include tablet {
			padding: 5 * $tablet 25 * $tablet;
			max-width: 280 * $tablet;
		}

		@include mobile {
			padding: 5 * $mobile 20 * $mobile;
			max-width: 260 * $mobile;
		}
	}

	&__title {
		margin: 0;
		font-weight: 800;
		font-size: 20px;
		letter-spacing: 0.03px;
		line-height: 25px;
		color: $color-biscay;

		@include pc {
			font-size: 20 * $pc;
			letter-spacing: 0.03 * $pc;
			line-height: 25 * $pc;
		}

		@include tablet {
			font-size: 20 * $tablet;
			letter-spacing: 0.03 * $tablet;
			line-height: 25 * $tablet;
		}

		@include mobile {
			font-size: 20 * $mobile;
			letter-spacing: 0.03 * $mobile;
			line-height: 24 * $mobile;
		}
	}

	&__date {
		position: relative;
		margin: 8px 0 0;
		padding-top: 12px;
		font-weight: 400;
		font-size: 12px;
		letter-spacing: 0.07px;
		line-height: 16px;
		color: $color-inch-worm;

		@include pc {
			margin: 8 * $pc 0 0;
			padding-top: 12 * $pc;
			font-size: 12 * $pc;
			letter-spacing: 0.07 * $pc;
			line-height: 16 * $pc;
		}

		@include tablet {
			margin: 8 * $tablet 0 0;
			padding-top: 12 * $tablet;
			font-size: 12 * $tablet;
			letter-spacing: 0.07 * $tablet;
			line-height: 16 * $tablet;
		}

		@include mobile {
			margin: 4 * $mobile 0 0;
			padding-top: 12 * $mobile;
			font-size: 12 * $mobile;
			letter-spacing: 0.07 * $mobile;
			line-height: 16 * $mobile;
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 43px;
			height: 2px;
			background-color: $color-inch-worm;

			@include pc {
				width: 43 * $pc;
				height: 2 * $pc;
			}

			@include tablet {
				width: 43 * $tablet;
				height: 2 * $tablet;
			}

			@include mobile {
				width: 43 * $mobile;
				height: 2 * $mobile;
			}
		}
	}

	&__description {
		margin: 20px 0 0;
		font-weight: 300;
		font-size: 12px;
		letter-spacing: 0.02px;
		line-height: 21px;
		color: $color-biscay;

		@include pc {
			margin: 20 * $pc 0 0;
			font-size: 12 * $pc;
			letter-spacing: 0.02 * $pc;
			line-height: 21 * $pc;
		}

		@include tablet {
			margin: 20 * $tablet 0 0;
			font-size: 12 * $tablet;
			letter-spacing: 0.02 * $tablet;
			line-height: 21 * $tablet;
		}

		@include mobile {
			margin: 16 * $mobile 0 0;
			font-size: 12 * $mobile;
			letter-spacing: 0.02 * $mobile;
			line-height: 16 * $mobile;
		}
	}

	&__action {
		display: flex;
		align-items: center;
		padding-top: 16px;
		text-decoration: none;
		font-size: 12px;
		line-height: 14px;
		color: $color-blue-zodiac;
		transition: color 0.3s ease;

		@include pc {
			padding-top: 16 * $pc;
			font-size: 12 * $pc;
			line-height: 14 * $pc;
		}

		@include tablet {
			padding-top: 16 * $tablet;
			font-size: 12 * $tablet;
			line-height: 14 * $tablet;
		}

		@include mobile {
			padding-top: 16 * $mobile;
			font-size: 12 * $mobile;
			line-height: 14 * $mobile;
		}

		svg {
			margin-left: 8px;
			width: 8px;
			height: 8px;
			fill: rgba($color-lima, 0.9);
			transition: fill 0.3s ease;

			@include pc {
				margin-left: 8 * $pc;
				width: 8 * $pc;
				height: 8 * $pc;
			}

			@include tablet {
				margin-left: 8 * $tablet;
				width: 8 * $tablet;
				height: 8 * $tablet;
			}

			@include mobile {
				margin-left: 8 * $mobile;
				width: 8 * $mobile;
				height: 8 * $mobile;
			}
		}

		&:hover {
			color: $color-lima;
		}
	}
}