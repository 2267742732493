.helper {
	&__content {
		display: flex;
		flex-shrink: 0;
		align-items: center;
		transition: opacity 0.3s linear;

		.is-right & {
			flex-direction: row-reverse;
		}

		.is-helper-open & {
			opacity: 0.2;
		}

		.is-active & {
			opacity: 1;
		}
	}

	&__title {
		position: absolute;
		flex-shrink: 0;
		white-space: nowrap;
		font-weight: 700;
		font-size: 10px;
		letter-spacing: 0.6px;
		line-height: 20px;
		text-transform: uppercase;
		color: $color-white;
		cursor: pointer;
		transform: translateX(-100%);

		@include pc {
			font-size: 10 * $pc;
			letter-spacing: 0.6 * $pc;
			line-height: 20 * $pc;
		}

		@include tablet {
			font-size: 10 * $tablet;
			letter-spacing: 0.6 * $tablet;
			line-height: 20 * $tablet;
		}

		@include mobile {
			position: absolute;
			top: 100%;
			left: 0;
			font-size: 9 * $mobile;
			letter-spacing: 0.6 * $mobile;
			line-height: 13 * $mobile;
			white-space: normal;
			transform: translateX(0);
		}

		.is-right & {
			transform: translateX(100%);

			@include mobile {
				left: auto;
				right: 0;
				text-align: right;
				transform: translateX(0);
			}
		}
	}

	&__toggler {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		margin: 0 0 0 13px;
		width: 28px;
		height: 28px;
		border-radius: 11px;
		font-weight: 900;
		font-size: 19px;
		background-color: $color-atlantis;
		color: $color-blue-zodiac;
		cursor: pointer;

		@include pc {
			margin: 0 0 0 13 * $pc;
			width: 28 * $pc;
			height: 28 * $pc;
			border-radius: 11 * $pc;
			font-size: 19 * $pc;

			.is-right & {
				margin: 0 13 * $pc 0 0;
			}
		}

		@include tablet {
			margin: 0 0 0 13 * $tablet;
			width: 28 * $tablet;
			height: 28 * $tablet;
			border-radius: 11 * $tablet;
			font-size: 19 * $tablet;

			.is-right & {
				margin: 0 13 * $tablet 0 0;
			}
		}

		@include mobile {
			margin: 0;
			width: 28 * $mobile;
			height: 28 * $mobile;
			border-radius: 11 * $mobile;
			font-size: 19 * $mobile;
		}

		&::before {
			content: "+";
		}

		.is-right & {

			@include mobile {
				margin: 0;
			}
		}
	}

	&__pointer {
		flex-shrink: 0;
		position: relative;
		width: 60px;
		height: 1px;
		background-color: rgba($color-white, 0.2);
		backface-visibility: hidden;

		@include pc {
			width: 60 * $pc;
			height: 1 * $pc;
		}

		@include tablet {
			width: 60 * $tablet;
			height: 1 * $tablet;
		}

		@include mobile {
			width: 110 * $mobile;
			height: 1 * $mobile;
		}

		&::before {
			content: "";
			position: absolute;
			right: 0;
			border-radius: 50%;
			width: 10px;
			height: 10px;
			background-color: $color-chathams-blue;
			transform: translateY(-50%);

			@include pc {
				width: 10 * $pc;
				height: 10 * $pc;
			}

			@include tablet {
				width: 10 * $tablet;
				height: 10 * $tablet;
			}

			@include mobile {
				width: 10 * $mobile;
				height: 10 * $mobile;
			}

			.is-right & {
				right: auto;
				left: 0;
			}
		}

		&::after {
			content: "";
			opacity: 0;
			position: absolute;
			right: -24px;
			border-radius: 50%;
			width: 10px;
			height: 10px;
			background-color: $color-red;
			transform: translateY(-50%);
			transition: opacity 0.6s ease;
			backface-visibility: hidden;

			@include pc {
				right: -24 * $pc;
				width: 10 * $pc;
				height: 10 * $pc;
			}

			@include tablet {
				right: -24 * $tablet;
				width: 10 * $tablet;
				height: 10 * $tablet;
			}

			@include mobile {
				right: -24 * $mobile;
				width: 10 * $mobile;
				height: 10 * $mobile;
			}

			.is-right & {
				right: auto;
				left: -24px;

				@include pc {
					left: -24 * $pc;
				}

				@include tablet {
					left: -24 * $tablet;
				}

				@include mobile {
					left: -24 * $mobile;
				}
			}
		}

		.is-num-110 & {
			width: 120px;

			@include pc {
				width: 120 * $pc;
			}

			@include tablet {
				width: 120 * $tablet;
			}

			@include mobile {
				width: 100 * $mobile;
			}
		}

		.is-num-50 & {
			@include mobile {
				width: 130 * $mobile;
			}
		}

		.is-right & {
			@include mobile {
				width: 100 * $mobile;
			}
		}

		.is-num-110 & {
			@include mobile {
				width: 90 * $mobile;
			}
		}

		.is-num-70 & {
			@include mobile {
				width: 110 * $mobile;
			}
		}
	}

	&__pulsar {
		position: absolute;
		content: "";
		top: 0;
		opacity: 0;
		transform: translateY(-50%);
		right: -31px;

		@include pc {
			right: -31 * $pc;
		}

		@include tablet {
			right: -31 * $tablet;
		}

		@include mobile {
			right: -31 * $mobile;
		}

		.is-right & {
			right: auto;
			left: -31px;

			@include pc {
				left: -31 * $pc;
			}

			@include tablet {
				left: -31 * $tablet;
			}

			@include mobile {
				left: -31 * $mobile;
			}
		}
	}

	&.is-active {
		z-index: 300;

		.helper {
			&__pointer {
				background: transparent;

				&::before {
					opacity: 0;
				}

				&::after {
					opacity: 1;
				}
			}

			&__pulsar {
				opacity: 1;
			}

			&__title,
			&__toggler {
				opacity: 0;
			}
		}
	}
}