.specialists {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;

	&__block {
		margin-bottom: 30px;
		width: 502px;
		max-width: 42%;

		&.is-hidden {
			display: none;
		}

		@include pc {
			margin-bottom: 30 * $pc;
			width: 502 * $pc;
		}

		@include tablet {
			margin-bottom: 30 * $tablet;
			max-width: 45%;
		}

		@include mobile {
			margin-bottom: 20 * $mobile;
			max-width: 100%;
			width: 100%;
		}

		&:nth-child(2) {
			padding-top: 110px;

			@include pc {
				padding-top: 110 * $pc;
			}

			@include tablet {
				padding-top: 50 * $tablet;
			}

			@include mobile {
				padding-top: 0;
			}
		}

		&:nth-child(2n) {
			margin-left: 20px;

			@include pc {
				margin-left: 20 * $pc;
			}

			@include tablet {
				margin-left: 20 * $tablet;
			}

			@include mobile {
				margin-left: 0;
			}
		}

		&:nth-child(2n + 3) {
			margin-top: -110px;
			
			@include pc {
				margin-top: -110 * $pc;
			}
			
			@include tablet {
				margin-top: -50 * $tablet;
			}
			
			@include mobile {
				margin-top: 0;
			}
		}

		&:last-child {
			margin-bottom: 0;

			@include tablet {
				margin-bottom: 30 * $tablet;
			}

			@include mobile {
				margin-bottom: 20 * $mobile;
			}
		}
	}

	&__action {
		position: relative;
		z-index: 30;
		display: flex;
		width: 50%;
		margin-top: -110px;
		
		@include pc {
			margin-top: -110 * $pc;
		}
		
		@include tablet {
			margin-top: -50 * $tablet;
		}
		
		@include mobile {
			display: flex;
			justify-content: center;
			margin-top: 0;
			width: 160 * $mobile;
		}
	}
}