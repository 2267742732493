.logo {
	display: flex;
	align-items: center;
	max-width: 230px;

	@include pc {
		max-width: 230 * $pc;
	}

	@include tablet {
		max-width: 230 * $tablet;
	}

	@include mobile {
		max-width: 100%;
	}

	&__symbol {
		margin-right: 18px;

		@include pc {
			margin-right: 18 * $pc;
		}
	
		@include tablet {
			margin-right: 18 * $tablet;
		}
	
		@include mobile {
			margin-right: 18 * $mobile;

			.header__fixed & {
				margin-right: 0;
			}
		}

		@media (max-width: 480px) {
			margin: 0;
		}
	}

	&__name {
		@include tablet {
			max-width: 150 * $tablet;
		}

		@include mobile {
			max-width: 150 * $mobile;
		}

		@media (max-width: 480px) {
			margin: 0 auto;
		}
	}

	.footer & {
		max-width: 256px;

		@include pc {
			max-width: 256 * $pc;
		}

		@include tablet {
			max-width: 256 * $tablet;
		}

		@include mobile {
			max-width: 256 * $mobile;
		}

		img {
			display: block;
			max-width: 100%;
		}
	}

	img {
		display: block;
	}
}