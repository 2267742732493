.location {
	position: relative;

	&__header {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
	}

	&__selected {
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.24px;
		line-height: 18px;
		color: $color-white;

		@include pc {
			font-size: 13 * $pc;
			letter-spacing: 0.24 * $pc;
			line-height: 18 * $pc;
		}

		@include tablet {
			font-size: 13 * $tablet;
			letter-spacing: 0.24 * $tablet;
			line-height: 18 * $tablet;
		}

		@include mobile {
			font-size: 13 * $mobile;
			letter-spacing: 0.24 * $mobile;
			line-height: 18 * $mobile;
		}
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 12px;
		border-radius: 50%;
		width: 21px;
		height: 21px;
		background-color: rgba($color-black, 0.2);

		@include pc {
			margin-left: 12 * $pc;
			width: 21 * $pc;
			height: 21 * $pc;
		}

		@include tablet {
			margin-left: 12 * $tablet;
			width: 21 * $tablet;
			height: 21 * $tablet;
		}

		@include mobile {
			margin-left: 12 * $mobile;
			width: 21 * $mobile;
			height: 21 * $mobile;
		}

		svg {
			width: 7px;
			height: 6px;
			fill: $color-white;

			@include pc {
				width: 7 * $pc;
				height: 6 * $pc;
			}

			@include tablet {
				width: 7 * $tablet;
				height: 6 * $tablet;
			}

			@include mobile {
				width: 7 * $mobile;
				height: 6 * $mobile;
			}
		}
	}

	&__list {
		opacity: 0;
		visibility: hidden;
		position: absolute;
		top: 100%;
		left: -20px;
		list-style: none;
		margin: 0;
		padding: 12px 20px;
		width: 160px;
		border-radius: 16px;
		background: rgba($color-black, 0.2);
		transform: translateY(8px);
		transition: z-index 0.3s step-end, opacity 0.3s ease, background 0.3s linear;

		&:hover {
			background: rgba($color-black, 0.35);
		}

		.header__fixed & {
			background: $color-blue-zodiac-3;

			&:hover {
				background: $color-blue-zodiac-3;
			}
		}
	}

	&__item {
		margin-bottom: 6px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__link {
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.24px;
		line-height: 18px;
		color: $color-white;
		cursor: pointer;
		text-decoration: none;
		transition: color 300ms linear;

		&:hover {
			color: $color-inch-worm;
		}

		@include pc {
			margin-bottom: 6 * $pc;
			font-size: 13 * $pc;
			letter-spacing: 0.24 * $pc;
			line-height: 18 * $pc;
		}

		@include tablet {
			margin-bottom: 6 * $tablet;
			font-size: 13 * $tablet;
			letter-spacing: 0.24 * $tablet;
			line-height: 18 * $tablet;
		}

		@include mobile {
			margin-bottom: 6 * $mobile;
			font-size: 13 * $mobile;
			letter-spacing: 0.24 * $mobile;
			line-height: 18 * $mobile;
		}
	}

	&:hover {
		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 8px;
			transform: translateY(8px);
		}

		.location {
			&__list {
				opacity: 1;
				visibility: visible;
				z-index: 20;
				transition: z-index 0.3s step-start, opacity 0.3s ease, background 0.3s linear;
			}
		}
	}
}