.mobile-menu {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: -100;
	opacity: 0;
	visibility: hidden;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	height: 100%;
	background-color: rgba($color-blue-zodiac-3, 0.98);
	transition: z-index 0.3s step-end, opacity 0.3s ease;

	&__header {
		display: flex;
		align-items: center;
		flex-direction: row;
		padding: 24 * $mobile 24 * $mobile;
	}

	&__footer {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 24 * $mobile 24 * $mobile;
	}

	&__location {
		margin-right: 40 * $mobile;
	}

	&__hamburger {
		position: fixed;

		@include mobile {
			right: 20 * $mobile;
			top: 12 * $mobile;
			
			.is-scrolling & {
				top: 9 * $mobile;
			}

			.hamburger.is-active {
				.hamburger-inner,
				.hamburger-inner::before,
				.hamburger-inner::after {
					background: $color-white;
				}
			}
		}
	}

	&__phone {
		margin-bottom: 16 * $mobile;
	}

	&__action {
		width: 100%;
		
		.btn {
			display: block;
		}
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		margin: auto;
		
		@media (orientation: landscape) {
			margin-top: 30 * $mobile;
		}
	}

	&__list {
		position: relative;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		display: block;
		margin-bottom: 20 * $mobile;

		&.is-alt {
			margin-bottom: 50 * $mobile;

			&:first-child {
				margin-bottom: 20 * $mobile;
			}
		}
	}

	&__link {
		display: block;
		font-weight: 500;
		font-size: 22 * $mobile;
		line-height: 35 * $mobile;
		text-decoration: none;
		text-transform: uppercase;
		color: $color-white;
		transition: all 0.3s;

		.is-alt & {
			font-size: 26 * $mobile;
		}

		&:hover,
		&.is-active {
			color: $color-inch-worm;
		}
		
		@media (orientation: landscape) {
			line-height: 40 * $mobile;
		}
	}

	@include mobile {
		&.is-open {
			z-index: 999;
			opacity: 1;
			visibility: visible;
			transition: z-index 0.3s step-start, opacity 0.3s ease;
		}
		
		@media (orientation: landscape) {
			padding-top: 46 * $mobile;
		}
	}
}