.schedule {
	&__title {
		margin: 0 0 6px;
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.02px;
		line-height: 14px;
		color: $color-mine-shaft;

		@include pc {
			margin: 0 0 6 * $pc;
			font-size: 13 * $pc;
			letter-spacing: 0.02 * $pc;
			line-height: 14 * $pc;
		}

		@include tablet {
			margin: 0 0 6 * $tablet;
			font-size: 13 * $tablet;
			letter-spacing: 0.02 * $tablet;
			line-height: 14 * $tablet;
		}

		@include mobile {
			margin: 0 0 6 * $mobile;
			font-size: 13 * $mobile;
			letter-spacing: 0.02 * $mobile;
			line-height: 14 * $mobile;
		}

		svg {
			margin-right: 11px;
			width: 11px;
			height: 11px;
			fill: rgba($color-black, 0.4);

			@include pc {
				margin-right: 11 * $pc;
				width: 11 * $pc;
				height: 11 * $pc;
			}

			@include tablet {
				margin-right: 11 * $tablet;
				width: 11 * $tablet;
				height: 11 * $tablet;
			}

			@include mobile {
				margin-right: 11 * $mobile;
				width: 11 * $mobile;
				height: 11 * $mobile;
			}
		}
	}

	&__week {
		list-style: none;
		margin: 0 0 10px;
		padding: 0 0 0 22px;

		@include pc {
			margin: 0 0 10 * $pc;
			padding: 0 0 0 22 * $pc;
		}

		@include tablet {
			margin: 0 0 10 * $tablet;
			padding: 0 0 0 22 * $tablet;
		}

		@include mobile {
			margin: 0 0 10 * $mobile;
			padding: 0 0 0 22 * $mobile;
		}
	}

	&__day {
		font-weight: 400;
		font-size: 11px;
		letter-spacing: 0.02px;
		line-height: 19px;
		color: rgba($color-mine-shaft, 0.9);

		@include pc {
			font-size: 11 * $pc;
			letter-spacing: 0.02 * $pc;
			line-height: 19 * $pc;
		}

		@include tablet {
			font-size: 11 * $tablet;
			letter-spacing: 0.02 * $tablet;
			line-height: 19 * $tablet;
		}

		@include mobile {
			font-size: 11 * $mobile;
			letter-spacing: 0.02 * $mobile;
			line-height: 19 * $mobile;
		}

		span {
			display: inline-block;
			width: 20px;

			@include pc {
				width: 20 * $pc;
			}

			@include tablet {
				width: 20 * $tablet;
			}

			@include mobile {
				width: 20 * $mobile;
			}
		}
	}
}