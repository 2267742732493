
@mixin pc {
	@media (min-width: 1101px) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: 1100px) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: 800px) {
		@content;
	}
}