.card {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;

	@include mobile {
		flex-direction: column;
	}

	&__photo {
		padding-left: 60px;
		width: percentage(1 / 2);
		
		@include pc {
			padding-left: 60 * $pc;
		}

		@include tablet {
			padding-left: 40 * $tablet;
		}

		@include mobile {
			padding-left: 0;
			width: percentage(1 / 1);
		}

		.is-one-third &,
		.is-certificate & {
			padding-left: 0;
			width: percentage(1 / 3);

			@include mobile {
				padding-left: 0;
				margin-bottom: 30 * $mobile;
				width: percentage(1 / 1);
			}
		}

		img {
			display: block;
			width: 100%;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-left: 60px;
		width: percentage(1 / 2);
		
		@include pc {
			padding-left: 60 * $pc;
		}
		
		@include tablet {
			padding-left: 60 * $tablet;
		}

		@include mobile {
			padding-left: 0;
			width: percentage(1 / 1);
		}

		.is-one-third &,
		.is-certificate & {
			padding-left: 6%;
			width: percentage(2 / 3);

			@include mobile {
				padding-left: 0;
				width: percentage(1 / 1);
			}
		}
	}

	&__header {
		display: flex;
		justify-content: space-between;
		margin: 0 0 40px;
		padding-left: 60px;
		width: 100%;
		
		@include pc {
			margin: 0 0 40 * $pc;
			padding-left: 60 * $pc;
		}
		
		@include tablet {
			margin: 0 0 40 * $tablet;
			padding-left: 40 * $tablet;
		}

		@include mobile {
			margin: 0 0 40 * $mobile;
			padding-left: 0;
		}

		&.is-doctor-header {
			padding-left: 0;
		}
	}

	&__prev {
		display: flex;
		align-items: center;
		font-weight: 400;
		font-size: 12px;
		letter-spacing: 0.02px;
		line-height: 14px;
		text-decoration: none;
		color: $color-mine-shaft;

		@include pc {
			font-size: 12 * $pc;
			letter-spacing: 0.02 * $pc;
			line-height: 14 * $pc;
		}

		@include tablet {
			font-size: 12 * $tablet;
			letter-spacing: 0.02 * $tablet;
			line-height: 14 * $tablet;
		}

		@include mobile {
			font-size: 12 * $mobile;
			letter-spacing: 0.02 * $mobile;
			line-height: 14 * $mobile;
		}

		svg {
			margin-right: 10px;
			width: 9px;
			height: 9px;
			fill: $color-mine-shaft;

			@include pc {
				margin-right: 10 * $pc;
				width: 9 * $pc;
				height: 9 * $pc;
			}

			@include tablet {
				margin-right: 10 * $tablet;
				width: 9 * $tablet;
				height: 9 * $tablet;
			}

			@include mobile {
				margin-right: 10 * $mobile;
				width: 9 * $mobile;
				height: 9 * $mobile;
			}
		}
	}

	&__title {
		margin: 0;
		font-weight: 300;
		font-size: 20px;
		line-height: 23px;
		color: rgba($color-mine-shaft, 0.3);

		@include pc {
			font-size: 20 * $pc;
			line-height: 23 * $pc;
		}

		@include tablet {
			font-size: 20 * $tablet;
			line-height: 23 * $tablet;
		}

		@include mobile {
			font-size: 20 * $mobile;
			line-height: 23 * $mobile;
		}

		span {
			display: block;
			font-weight: 700;
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		margin-top: auto;
	}

	&__teaser {
		margin-bottom: 30px;

		@include pc {
			margin-bottom: 30 * $pc;
		}

		@include tablet {
			margin-bottom: 30 * $tablet;
		}

		@include mobile {
			margin-bottom: 30 * $mobile;
		}
	}

	&__name {
		margin: 0 0 10px;
		font-weight: 800;
		font-size: 40px;
		letter-spacing: 0.07px;
		line-height: 49px;
		color: $color-mine-shaft;

		@include pc {
			margin: 0 0 10 * $pc;
			font-size: 40 * $pc;
			letter-spacing: 0.07 * $pc;
			line-height: 49 * $pc;
		}

		@include tablet {
			margin: 0 0 10 * $tablet;
			font-size: 32 * $tablet;
			letter-spacing: 0.07 * $tablet;
			line-height: 42 * $tablet;
		}

		@include mobile {
			margin: 0 0 10 * $mobile;
			font-size: 24 * $mobile;
			letter-spacing: 0.07 * $mobile;
			line-height: 32 * $mobile;
		}
	}

	&__position {
		position: relative;
		margin: 0 0 30px;
		padding-bottom: 20px;
		font-weight: 400;
		font-size: 16px;
		letter-spacing: 0.07px;
		line-height: 20px;
		color: rgba($color-lima, 0.9);

		@include pc {
			margin: 0 0 30 * $pc;
			padding-bottom: 20 * $pc;
			font-size: 16 * $pc;
			letter-spacing: 0.07 * $pc;
			line-height: 20 * $pc;
		}

		@include tablet {
			margin: 0 0 30 * $tablet;
			padding-bottom: 20 * $tablet;
			font-size: 16 * $tablet;
			letter-spacing: 0.07 * $tablet;
			line-height: 20 * $tablet;
		}

		@include mobile {
			margin: 0 0 30 * $mobile;
			padding-bottom: 20 * $mobile;
			font-size: 16 * $mobile;
			letter-spacing: 0.07 * $mobile;
			line-height: 20 * $mobile;
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 43px;
			height: 4px;
			background-color: $color-inch-worm;

			@include pc {
				width: 43 * $pc;
				height: 4 * $pc;
			}

			@include tablet {
				width: 43 * $tablet;
				height: 4 * $tablet;
			}

			@include mobile {
				width: 43 * $mobile;
				height: 4 * $mobile;
			}
		}
	}

	&__description {
		margin-bottom: 30px;

		@include pc {
			margin-bottom: 30 * $pc;
		}

		@include tablet {
			margin-bottom: 30 * $tablet;
		}

		@include mobile {
			margin-bottom: 30 * $mobile;
		}

		&, p {
			overflow: auto;
			height: 380px;
			font-weight: 300;
			font-size: 14px;
			letter-spacing: 0.02px;
			line-height: 23px;
			color: $color-mine-shaft;

			&:focus {
				outline: none;
			}

			@include pc {
				font-size: 14 * $pc;
				letter-spacing: 0.02 * $pc;
				line-height: 23 * $pc;
			}

			@include tablet {
				font-size: 14 * $tablet;
				letter-spacing: 0.02 * $tablet;
				line-height: 23 * $tablet;
			}

			@include mobile {
				font-weight: 400;
				margin-bottom: 30 * $mobile;
				font-size: 14 * $mobile;
				letter-spacing: 0.02 * $mobile;
				line-height: 23 * $mobile;
			}
		}

		p {
			height: auto;
		}
	}

	&__stats {
		display: flex;
		align-items: center;
		margin-right: auto;

		&__item {
			display: flex;
			align-items: center;
			margin-right: 34px;
			font-size: 12px;
			font-weight: 700;
			letter-spacing: 0.02px;
			line-height: 14px;
			color: $color-mine-shaft;

			@include pc {
				margin-right: 34 * $pc;
				font-size: 12 * $pc;
				letter-spacing: 0.02 * $pc;
				line-height: 14 * $pc;
			}

			@include tablet {
				margin-right: 34 * $tablet;
				font-size: 12 * $tablet;
				letter-spacing: 0.02 * $tablet;
				line-height: 14 * $tablet;
			}

			@include mobile {
				margin-right: 34 * $mobile;
				font-size: 12 * $mobile;
				letter-spacing: 0.02 * $mobile;
				line-height: 14 * $mobile;
			}

			&:last-child {
				margin-right: 0;
			}

			span {
				display: block;
				margin: 0 10px 0 0;
				font-weight: 700;
				font-size: 25px;
				letter-spacing: 0.04px;
				line-height: 25px;
				color: $color-mine-shaft;

				@include pc {
					margin: 0 10 * $pc 0 0;
					font-size: 25 * $pc;
					letter-spacing: 0.04 * $pc;
					line-height: 25 * $pc;
				}

				@include tablet {
					margin: 0 10 * $tablet 0 0;
					font-size: 25 * $tablet;
					letter-spacing: 0.04 * $tablet;
					line-height: 25 * $tablet;
				}

				@include mobile {
					margin: 0 10 * $mobile 0 0;
					font-size: 25 * $mobile;
					letter-spacing: 0.04 * $mobile;
					line-height: 25 * $mobile;
				}
			}
		}
	}

	&__action {
		display: flex;
		align-items: center;
		text-decoration: none;

		&:hover {
			.card__action__icon {
				svg {
					fill: darken($color-inch-worm, 7%);
				}
			}
		}

		&__icon {
			margin-right: 10px;
			
			@include pc {
				margin-right: 10 * $pc;
			}

			@include tablet {
				margin-right: 10 * $tablet;
			}

			@include mobile {
				margin-right: 10 * $mobile;
			}

			svg {
				width: 40px;
				height: 40px;
				fill: $color-inch-worm;
				transition: fill 0.3s linear;

				@include pc {
					width: 40 * $pc;
					height: 40 * $pc;
				}

				@include tablet {
					width: 40 * $tablet;
					height: 40 * $tablet;
				}

				@include mobile {
					width: 40 * $mobile;
					height: 40 * $mobile;
				}
			}
		}

		&__text {
			font-weight: 400;
			font-size: 12px;
			letter-spacing: 0.02px;
			line-height: 14px;
			color: $color-mine-shaft;

			@include pc {
				font-size: 12 * $pc;
				letter-spacing: 0.02 * $pc;
				line-height: 14 * $pc;
			}

			@include tablet {
				font-size: 12 * $tablet;
				letter-spacing: 0.02 * $tablet;
				line-height: 14 * $tablet;
			}

			@include mobile {
				display: none;
				font-size: 12 * $mobile;
				letter-spacing: 0.02 * $mobile;
				line-height: 14 * $mobile;
			}
		}
	}
}