.order-form {
	display: flex;
	align-items: center;

	@include mobile {
		flex-direction: column;
	}

	.is-vertical & {
		flex-direction: column;
	}

	&__input {
		margin-left: 28px;
		max-width: 330px;
		width: 100%;

		label.is-hidden {
			overflow: hidden;
			visibility: hidden;
			display: block;
			font-size: 0;
			line-height: 0;
			height: 0;
		}

		@include pc {
			margin-left: 28 * $pc;
			max-width: 330 * $pc;
		}

		@include tablet {
			margin-left: 28 * $tablet;
			max-width: 260 * $tablet;
		}

		@include mobile {
			margin-left: 0;
			max-width: 100%;
		}

		&:first-child {
			margin-left: 0;
		}

		.is-vertical & {
			margin-left: 0;
			max-width: 100%;
		}

		input {
			display: block;
			max-width: 330px;
			width: 100%;
			outline: none;
			border: none;
			padding: 18px 23px;
			height: 54px;
			background-color: $color-white;
			font-weight: 800;
			font-size: 11px;
			letter-spacing: 0.48px;
			line-height: 18px;
			text-transform: uppercase;
			color: $color-black;

			@include pc {
				max-width: 330 * $pc;
				padding: 18 * $pc 23 * $pc;
				height: 54 * $pc;
				font-size: 11 * $pc;
				letter-spacing: 0.48 * $pc;
				line-height: 18 * $pc;
			}

			@include tablet {
				max-width: 330 * $tablet;
				padding: 18 * $tablet 23 * $tablet;
				height: 54 * $tablet;
				font-size: 11 * $tablet;
				letter-spacing: 0.48 * $tablet;
				line-height: 18 * $tablet;
			}

			@include mobile {
				margin-bottom: 12 * $mobile;
				padding: 18 * $mobile 23 * $mobile;
				height: 54 * $mobile;
				max-width: 100%;
				width: 100%;
				font-size: 11 * $mobile;
				letter-spacing: 0.48 * $mobile;
				line-height: 18 * $mobile;
			}

			.is-vertical & {
				margin-bottom: 12 * $mobile;
				max-width: 100%;
				width: 100%;
			}
		}
	}

	&__submit {
		max-width: 275px;
		width: 100%;

		@include pc {
			max-width: 275 * $pc;
		}

		@include tablet {
			max-width: 275 * $tablet;
		}

		@include mobile {
			max-width: 100%;
		}

		.is-vertical & {
			max-width: 100%;
		}

		button {
			display: block;
			max-width: 275px;
			width: 100%;
			outline: none;
			border: none;
			padding: 18px 23px;
			height: 54px;
			background-color: $color-inch-worm;
			font-weight: 800;
			font-size: 11px;
			letter-spacing: 0.48px;
			line-height: 18px;
			text-transform: uppercase;
			color: $color-black;

			@include pc {
				max-width: 275 * $pc;
				padding: 18 * $pc 23 * $pc;
				height: 54 * $pc;
				font-size: 11 * $pc;
				letter-spacing: 0.48 * $pc;
				line-height: 18 * $pc;
			}

			@include tablet {
				max-width: 275 * $tablet;
				padding: 18 * $tablet 23 * $tablet;
				height: 54 * $tablet;
				font-size: 11 * $tablet;
				letter-spacing: 0.48 * $tablet;
				line-height: 18 * $tablet;
			}

			@include mobile {
				max-width: 100%;
				padding: 18 * $mobile 23 * $mobile;
				height: 54 * $mobile;
				font-size: 11 * $mobile;
				letter-spacing: 0.48 * $mobile;
				line-height: 18 * $mobile;
			}

			.is-vertical & {
				max-width: 100%;
			}
		}
	}
}