
// Settings
// ==================================================
$hamburger-padding-x                       : 11px !default;
$hamburger-padding-y                       : 11px !default;
$hamburger-layer-width                     : 28px !default;
$hamburger-layer-height                    : 3px !default;
$hamburger-layer-spacing                   : 7px !default;
$hamburger-layer-color                     : rgba($color-white) !default;
$hamburger-layer-border-radius             : 3px !default;
$hamburger-hover-opacity                   : 1 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

.hamburger:focus {
	outline: none;
}
.hamburger-inner::before,
.hamburger-inner::after {
	background-color: inherit!important;
}
