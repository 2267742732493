.menu {
	list-style: none;
	margin: 0;
	padding: 0;

	&__item {
		display: block;
		margin-bottom: 10px;

		@include pc {
			margin-bottom: 10 * $pc;
		}

		@include tablet {
			margin-bottom: 10 * $tablet;
		}

		@include mobile {
			margin-bottom: 0;
		}
	}

	&__link {
		display: block;
		font-weight: 600;
		font-size: 16px;
		letter-spacing: 0.03px;
		line-height: 25px;
		text-decoration: none;
		color: $color-biscay;
		transition: color 0.3s ease;

		@include pc {
			font-size: 16 * $pc;
			letter-spacing: 0.03 * $pc;
			line-height: 25 * $pc;
		}

		@include tablet {
			font-size: 16 * $tablet;
			letter-spacing: 0.03 * $tablet;
			line-height: 25 * $tablet;
		}

		@include mobile {
			border-bottom: 1px solid $color-alto-1;
			padding: 10 * $mobile $gutter-lg * $mobile;
			font-size: 16 * $mobile;
			letter-spacing: 0.03 * $mobile;
			line-height: 25 * $mobile;
		}

		&:hover {
			color: $color-inch-worm;
		}
	}
}