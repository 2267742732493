.service {
	display: flex;
	flex-wrap: wrap;

	&__item {
		display: flex;
		position: relative;
		padding: 0 60px 70px;
		width: percentage(1 / 4);
		height: 70vh;
		text-decoration: none;
		transition: background-color 0.3s ease;
		
		@include pc {
			padding: 0 40 * $pc 70 * $pc;
		}

		@include tablet {
			margin-bottom: 30 * $tablet;
			padding: 20 * $tablet 40 * $tablet 40 * $tablet;
			width: percentage(1 / 2);
			height: auto;
		}

		@include mobile {
			margin-bottom: 30 * $mobile;
			padding: 20 * $mobile 20 * $mobile 40 * $mobile;
			width: percentage(1 / 1);
			height: auto;
		}

		&:hover {
			.is-device-desktop & {
				background-color: $color-inch-worm;
				.service {
					&__title {
						color: $color-white;
					}

					&__description {
						&,
						p {
							color: $color-white;
						}
					}

					&__readmore {
						opacity: 1;

						svg {
							fill: $color-white;
						}
					}
				}
			}
		}
	}

	&__content {
		margin-top: auto;
	}

	&__title {
		margin: 0 0 35px;
		max-width: 135px;
		font-weight: 800;
		font-size: 23px;
		line-height: 25px;
		color: $color-biscay;
		transition: color 0.3s ease;

		@include pc {
			margin: 0 0 35 * $pc;
			max-width: 135 * $pc;
			font-size: 23 * $pc;
			line-height: 25 * $pc;
		}

		@include tablet {
			margin: 0 0 35 * $tablet;
			max-width: 135 * $tablet;
			font-size: 23 * $tablet;
			line-height: 25 * $tablet;
		}

		@include mobile {
			margin: 0 0 35 * $mobile;
			max-width: 135 * $mobile;
			font-size: 23 * $mobile;
			line-height: 25 * $mobile;
		}

		&::first-line {
			font-weight: 400;
		}
	}

	&__description {
		&,
		p {
			font-weight: 300;
			font-size: 13px;
			letter-spacing: 0.02px;
			line-height: 21px;
			color: $color-biscay;
			transition: color 0.3s ease;

			@include pc {
				font-size: 13 * $pc;
				letter-spacing: 0.02 * $pc;
				line-height: 21 * $pc;
			}
	
			@include tablet {
				font-size: 13 * $tablet;
				letter-spacing: 0.02 * $tablet;
				line-height: 21 * $tablet;
			}
	
			@include mobile {
				font-weight: 400;
				font-size: 13 * $mobile;
				letter-spacing: 0.02 * $mobile;
				line-height: 21 * $mobile;
			}
		}
	}

	&__readmore {
		opacity: 0;
		position: absolute;
		bottom: 20px;
		right: 20px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		transition: opacity 0.3s ease;

		@include pc {
			right: 20 * $pc;
			bottom: 20 * $pc;
		}

		@include tablet {
			right: 20 * $tablet;
			bottom: 20 * $tablet;
		}

		@include mobile {
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 1;
			right: 20 * $mobile;
			bottom: 0 * $mobile;
			width: 34 * $mobile;
			height: 34 * $mobile;
			background: rgba($color-lima, 0.2);
		}

		svg {
			width: 17px;
			height: 17px;
			fill: $color-white;
			transition: fill 0.5s ease;

			@include pc {
				width: 17 * $pc;
				height: 17 * $pc;
			}

			@include tablet {
				width: 17 * $tablet;
				height: 17 * $tablet;
			}

			@include mobile {
				width: 17 * $mobile;
				height: 17 * $mobile;
				fill: $color-biscay;
			}
		}

		&:hover svg {
			fill: $color-white;
		}
	}
}

@keyframes pulse-background {
	0% {
		background-color: rgba($color-inch-worm, 0);
	}

	50% {
		background-color: rgba($color-inch-worm, 1);
	}

	100% {
		background-color: rgba($color-inch-worm, 0);
	}
}