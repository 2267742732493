.lang {
	&__list {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 12px;
		width: 30px;
		height: 30px;
		border: 2px solid transparent;

		@include pc {
			margin-right: 12 * $pc;
			width: 30 * $pc;
			height: 30 * $pc;
			border: 2 * $pc solid transparent;
		}

		@include tablet {
			margin-right: 12 * $tablet;
			width: 30 * $tablet;
			height: 30 * $tablet;
			border: 2 * $tablet solid transparent;
		}

		@include mobile {
			margin-right: 12 * $mobile;
			width: 30 * $mobile;
			height: 30 * $mobile;
			border: 2 * $mobile solid transparent;
		}

		&:last-child {
			margin-right: 0;
		}

		&.is-active {
			border: 2px solid $color-blue-zodiac-1;

			@include pc {
				border: 2 * $pc solid $color-blue-zodiac-1;
			}

			@include tablet {
				border: 2 * $tablet solid $color-blue-zodiac-1;
			}

			@include mobile {
				border: 2 * $mobile solid $color-blue-zodiac-1;
			}
		}

		.is-light &.is-active {
			border: 2px solid $color-lima;

			@include pc {
				border: 2 * $pc solid $color-lima;
			}

			@include tablet {
				border: 2 * $tablet solid $color-lima;
			}

			@include mobile {
				border: 2 * $mobile solid $color-lima;
			}
		}
	}

	&__link {
		display: block;
		width: 100%;
		height: 100%;
		font-weight: 700;
		font-size: 11px;
		letter-spacing: 0.24px;
		line-height: 26px;
		text-decoration: none;
		text-transform: uppercase;
		text-align:center;
		color: $color-silver;
		transition: color 0.3s ease;

		@include pc {
			font-size: 11 * $pc;
			letter-spacing: 0.24 * $pc;
			line-height: 26 * $pc;
		}

		@include tablet {
			font-size: 11 * $tablet;
			letter-spacing: 0.24 * $tablet;
			line-height: 26 * $tablet;
		}

		@include mobile {
			font-size: 11 * $mobile;
			letter-spacing: 0.24 * $mobile;
			line-height: 26 * $mobile;
		}

		&:hover,
		.is-active & {
			color: $color-blue-zodiac-3;
		}

		.is-light & {
			color: $color-white;
		}

		.is-light &:hover,
		.is-light .is-active & {
			color: $color-lima;
		}
	}
}