// stylelint-disable scss/dollar-variable-empty-line-before

// APAPRIVE SIZES

$pc: 1 / 1200 * 100vw;
$tablet: 1 / 1000 * 100vw;
$mobile: 1 / 360 * 100vmin;

$container-xlg: 100%;
$container-lg: 100%;
$container-md: 100%;
$container-sm: 100%;

$gutter-lg: 30;
$gutter-md: 20px;

// COLORS

$color-black: #000;
$color-white: #fff;

$color-mine-shaft: #333333;
$color-bunting: #101f3c;
$color-biscay: #19375f;
$color-blue-zodiac: #14264a;
$color-blue-zodiac-1: #10295c;
$color-blue-zodiac-2: #0c1a33;
$color-blue-zodiac-3: #10295b;
$color-madison: #0e2b65;
$color-madison-1: #0d2c6b;
$color-madison-2: #0e2b66;
$color-madison-3: #0d2c6a;
$color-chathams-blue: #113c74;
$color-lima: #78c418;
$color-atlantis: #8cde29;
$color-atlantis-1: #8bdc29;
$color-inch-worm: #94ea26;
$color-athens-gray: #f6f7f9;
$color-alto: #cfcfcf;
$color-alto-1: #d9d9d9;
$color-mystic: #eceff4;
$color-mystic-1: #dde2e9;
$color-silver: #cacaca;
$color-amber: #ffc000;
$color-torch-red: #ff0036;
$color-red: #ff0018;

// FONTS
