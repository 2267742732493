.pagination {
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 0;

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 60px;
		padding: 0;
		list-style: none;

		@include pc {
			margin-bottom: 60 * $pc;
		}

		@include tablet {
			margin-bottom: 60 * $tablet;
		}

		@include mobile {
			margin-bottom: 60 * $mobile;
		}
	}

	&__item {
		margin: 0 6px 10px;

		@include pc {
			margin: 0 6 * $pc 10 * $pc;
		}

		@include tablet {
			margin: 0 6 * $tablet 10 * $tablet;
		}
		
		@include mobile {
			margin: 0 6 * $mobile 10 * $mobile;
		}
	}

	&__link {
		display: block;
		border: 2px solid $color-blue-zodiac-3;
		padding: 8px 16px;
		width: 100%;
		height: 100%;
		font-weight: 500;
		font-size: 16px;
		letter-spacing: 0.24px;
		line-height: 21px;
		text-decoration: none;
		text-align:center;
		color: $color-blue-zodiac-3;
		transition: color 0.3s ease,  border-color 0.3s ease;

		@include pc {
			padding: 8 * $pc 16 * $pc;
			border-width: 2 * $pc;
			font-size: 16 * $pc;
			letter-spacing: 0.24 * $pc;
			line-height: 21 * $pc;
		}

		@include tablet {
			padding: 8 * $tablet 16 * $tablet;
			border-width: 2 * $tablet;
			font-size: 16 * $tablet;
			letter-spacing: 0.24 * $tablet;
			line-height: 21 * $tablet;
		}

		@include mobile {
			padding: 8 * $mobile 16 * $mobile;
			border-width: 2 * $mobile;
			font-size: 16 * $mobile;
			letter-spacing: 0.24 * $mobile;
			line-height: 21 * $mobile;
		}

		&:hover,
		.is-active & {
			border-color: $color-lima;
			color: $color-lima;
		}
	}
}