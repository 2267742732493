.nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0;
	padding: 0;

	.slider--review & {
		@include tablet {
			flex-direction: column;
		}

		@include mobile {
			flex-direction: column;
		}
	}
	
	&__list {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
		width: 100%;

		.slider--review & {
			max-width: 73%;
		}
	}

	&__item {
		margin-right: 40px;

		@include pc {
			margin-right: 40 * $pc;
		}

		@include tablet {
			margin-right: 20 * $tablet;
		}

		@include mobile {
			margin-right: 16 * $mobile;
		}

		.header & {
			margin-right: 24px;
	
			@include pc {
				margin-right: 24 * $pc;
			}
	
			@include tablet {
				margin-right: 12 * $tablet;
			}
	
			@include mobile {
				margin-right: 12 * $mobile;
			}
		}
	}

	&__link {
		display: flex;
		align-items: center;
		font-weight: 400;
		font-size: 15px;
		line-height: 18px;
		text-decoration: none;
		color: $color-white;
		transition: color 0.3s ease;
		cursor: pointer;

		@include pc {
			font-size: 15 * $pc;
			line-height: 18 * $pc;
		}

		@include tablet {
			font-size: 15 * $tablet;
			line-height: 18 * $tablet;
		}

		@include mobile {
			padding: 6 * $mobile 0;
			width: 100%;
			font-size: 18 * $mobile;
			line-height: 24 * $mobile;
		}

		svg {
			display: block;
			margin-right: 8px;
			width: 16px;
			height: 16px;
			fill: $color-white;

			@include pc {
				margin-right: 8 * $pc;
				width: 16 * $pc;
				height: 16 * $pc;
			}

			@include tablet {
				margin-right: 8 * $tablet;
				width: 16 * $tablet;
				height: 16 * $tablet;
			}

			@include mobile {
				margin-right: 8 * $mobile;
				width: 16 * $mobile;
				height: 16 * $mobile;
			}
		}

		.slick-current &,
		&.is-current,
		&:hover {
			color: $color-inch-worm;

			svg {
				fill: $color-inch-worm;
			}
		}
	}

	&__action {
		margin: 0 16% 0 auto;
		max-width: 200px;

		@include pc {
			max-width: 200 * $pc;
		}
		
		@include tablet {
			max-width: 200 * $tablet;
			margin: 30 * $tablet auto 0;
		}

		@include mobile {
			max-width: 200 * $mobile;
			margin: 30 * $mobile auto 0;
		}

		.btn {
			white-space: nowrap;
		}
	}
}