.phone-teaser {
	display: flex;
	align-items: center;
	text-decoration: none;

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 28px;
		height: 28px;
		border: 2px solid $color-inch-worm;

		@include pc {
			width: 28 * $pc;
			height: 28 * $pc;
			border: 2 * $pc solid $color-inch-worm;
		}

		@include tablet {
			width: 28 * $tablet;
			height: 28 * $tablet;
			border: 2 * $tablet solid $color-inch-worm;
		}

		@include mobile {
			width: 28 * $mobile;
			height: 28 * $mobile;
			border: 2 * $mobile solid $color-inch-worm;
		}

		svg {
			width: 13px;
			height: 13px;
			fill: $color-inch-worm;

			@include pc {
				width: 13 * $pc;
				height: 13 * $pc;
			}

			@include tablet {
				width: 13 * $tablet;
				height: 13 * $tablet;
			}

			@include mobile {
				width: 13 * $mobile;
				height: 13 * $mobile;
			}
		}
	}

	&__content {
		margin-left: 18px;

		@include pc {
			margin-left: 18 * $pc;
		}

		@include tablet {
			margin-left: 18 * $tablet;
		}

		@include mobile {
			margin-left: 18 * $mobile;
		}
	}

	&__text {
		margin: 0 0 4px;
		font-weight: 300;
		font-size: 14px;
		letter-spacing: 0.01px;
		line-height: 14px;
		text-transform: uppercase;
		color: $color-mine-shaft;

		@include pc {
			margin: 0 0 4 * $pc;
			font-size: 14 * $pc;
			letter-spacing: 0.01 * $pc;
			line-height: 14 * $pc;
		}

		@include tablet {
			margin: 0 0 4 * $tablet;
			font-size: 14 * $tablet;
			letter-spacing: 0.01 * $tablet;
			line-height: 14 * $tablet;
		}

		@include mobile {
			margin: 0 0 4 * $mobile;
			font-size: 14 * $mobile;
			letter-spacing: 0.01 * $mobile;
			line-height: 14 * $mobile;

			&.is-white-on-mobile {
				color: $color-white;
			}
		}

		.is-vertical & {
			color: $color-white;
		}

		&.is-alt {
			margin: 0;
			font-weight: 700;
			font-size: 16px;
			letter-spacing: 0.04px;
			line-height: 16px;
			color: $color-inch-worm;

			@include pc {
				font-size: 16 * $pc;
				letter-spacing: 0.04 * $pc;
				line-height: 16 * $pc;
			}

			@include tablet {
				font-size: 16 * $tablet;
				letter-spacing: 0.04 * $tablet;
				line-height: 16 * $tablet;
			}

			@include mobile {
				font-size: 16 * $mobile;
				letter-spacing: 0.04 * $mobile;
				line-height: 16 * $mobile;
			}
		}
	}
}