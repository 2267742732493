*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
}

body {
	font-family: "Proxima Nova", sans-serif;

	.is-modal-opened & {
		overflow: hidden;
	}
}

.is-os-ios.is-modal-opened {
	body,
	& {
		overflow: hidden;
		position: relative;
		height: 100%;
	}
}

a,
a:visited,
a:hover,
a:focus {
	text-decoration: none;
}

.container {
	margin: 0 auto;
	padding: 0 20px;
	max-width: $container-md;

	@include pc {
		padding: 0 20 * $pc;
		max-width:  1600px;
	}
}

.is-hidden-on-pc {
	@include pc {
		display: none;
	}
}

.is-visual-hidden {
	// stylelint-disable-next-line declaration-no-important
	opacity: 0 !important;
	// stylelint-disable-next-line declaration-no-important
	visibility: hidden !important;
	// stylelint-disable-next-line declaration-no-important
	pointer-events: none !important;
}