.video {
	padding: 10px;

	@include pc {
		padding: 10 * $pc;
	}

	@include tablet {
		padding: 10 * $tablet;
	}

	@include mobile {
		padding: 10 * $mobile;
	}

	iframe {
		display: block;
		width: 100%;
	}
}