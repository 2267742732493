.slider {
	width: 100%;

	&--hero {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		margin: auto 0;

		.slider {
			&__inner {
				padding-bottom: 100px;

				@include pc {
					padding-bottom: 80 * $pc;
				}

				@include tablet {
					padding-bottom: 80 * $tablet;
				}

				@include mobile {
					padding-bottom: 80 * $mobile;
				}
			}

			&__left {
				position: relative;
				z-index: 10;
				margin-right: -180px;
				padding-left: 50px;

				@include pc {
					margin-right: -180 * $pc;
					padding-left: 50 * $pc;
				}

				@include tablet {
					margin-right: -240 * $tablet;
					padding-left: 50 * $tablet;
				}

				@include mobile {
					margin: 0;
					margin-bottom: 20 * $mobile;
					padding-left: 0;
				}

				&__item {
					padding-left: 10px;
					
					@include pc {
						padding-left: 10 * $pc;
					}

					@include tablet {
						padding-left: 10 * $tablet;
					}

					@include mobile {
						padding-left: 6 * $mobile;
					}
				}

				&__inner {
					visibility: hidden;

					&.slick-initialized {
						visibility: visible;
					}
				}
			}

			&__media {
				width: 585px;
				height: 396px;
		
				@include pc {
					width: 585 * $pc;
					height: 396 * $pc;
				}
		
				@include tablet {
					width: 490 * $tablet;
					height: 330 * $tablet;
				}
		
				@include mobile {
					width: 100%;
					height: auto;
				}

				img {
					display: block;
					width: 100%;
					height: 396px;
					object-fit: cover;
					font-family: 'object-fit: cover;';
		
					@include pc {
						height: 396 * $pc;
					}
			
					@include tablet {
						height: 330 * $tablet;
					}
			
					@include mobile {
						object-fit: contain;
						font-family: 'object-fit: contain;';
						height: auto;
					}
				}
			}

			&__right {
				max-width: 57%;
				padding: 0 25px 35px;

				@include pc {
					padding: 0 25 * $pc 35 * $pc;
				}

				@include tablet {
					padding: 0 25 * $tablet 35 * $tablet;
					max-width: 58%;
				}

				@include mobile {
					margin: 0 0 25 * $mobile;
					padding: 0;
					max-width: 100%;
					width: 100%;
				}

				&__inner {
					opacity: 0;
					height: 0;
					transition: opacity 300ms linear;

					&.slick-initialized {
						height: auto;
						opacity: 1;
					}
				}
			}

			&__content {
				padding: 0 0 0 10px;
				text-decoration: none;

				@include pc {
					padding: 0 0 0 10 * $pc;
				}

				@include tablet {
					padding: 0 0 0 10 * $tablet;
				}

				@include mobile {
					flex-direction: column-reverse;
					padding: 0;
				}
			}

			&__right__media {
				font-size: 0;
				line-height: 0;
				box-shadow: 0 13px 34px 2px rgba(15, 35, 61, 0.23);

				@include pc {
					box-shadow: 0 13 * $pc 34 * $pc 2 * $pc rgba(15, 35, 61, 0.23);
				}

				@include tablet {
					box-shadow: 0 13 * $tablet 34 * $tablet 2 * $tablet rgba(15, 35, 61, 0.23);
				}

				@include mobile {
					box-shadow: 0 13 * $mobile 34 * $mobile 2 * $mobile rgba(15, 35, 61, 0.23);
				}
			}

			&__controls {
				position: absolute;
				top: 50%;
				flex-shrink: 0;
				align-items: center;
				justify-content: center;
				transform: rotate(90deg) translateY(-100%) translateX(-50%);

				@include mobile {
					display: none;
				}
			}

			&__status {
				display: flex;
				min-width: 33px;
				flex-shrink: 0;
				font-weight: 700;
				font-size: 16px;
				margin-right: 13px;
				letter-spacing: 0.02px;
				line-height: 16px;
				color: $color-blue-zodiac;
				text-align: center;

				@include pc {
					min-width: 33 * $pc;
					font-size: 16 * $pc;
					margin-right: 13 * $pc;
					letter-spacing: 0.02 * $pc;
					line-height: 16 * $pc;
				}

				@include tablet {
					min-width: 33 * $tablet;
					font-size: 16 * $tablet;
					margin-right: 13 * $tablet;
					letter-spacing: 0.02 * $tablet;
					line-height: 16 * $tablet;
				}

				@include mobile {
					min-width: 33 * $mobile;
					font-size: 16 * $mobile;
					margin-right: 13 * $mobile;
					letter-spacing: 0.02 * $mobile;
					line-height: 16 * $mobile;
				}
			}

			&__pagination {
				display: flex;

				@include mobile {
					display: none;
				}

				ul {
					display: flex;
					flex-shrink: 0;
					list-style: none;
					margin: 0;
					padding: 0;
				}

				li {
					height: 4px;

					@include pc {
						height: 4 * $pc;
					}

					@include tablet {
						height: 4 * $tablet;
					}

					@include mobile {
						height: 4 * $mobile;
					}

					[data-theme-id="1"] &:nth-child(1) {
						button {
							background-color: $color-inch-worm;
						}
					}

					[data-theme-id="2"] &:nth-child(1),
					[data-theme-id="2"] &:nth-child(2) {
						button {
							background-color: $color-inch-worm;
						}
					}

					[data-theme-id="3"] &:nth-child(1),
					[data-theme-id="3"] &:nth-child(2),
					[data-theme-id="3"] &:nth-child(3) {
						button {
							background-color: $color-inch-worm;
						}
					}

					[data-theme-id="4"] &:nth-child(1),
					[data-theme-id="4"] &:nth-child(2),
					[data-theme-id="4"] &:nth-child(3),
					[data-theme-id="4"] &:nth-child(4) {
						button {
							background-color: $color-inch-worm;
						}
					}

					[data-theme-id="5"] &:nth-child(1),
					[data-theme-id="5"] &:nth-child(2),
					[data-theme-id="5"] &:nth-child(3),
					[data-theme-id="5"] &:nth-child(4),
					[data-theme-id="5"] &:nth-child(5) {
						button {
							background-color: $color-inch-worm;
						}
					}

					[data-theme-id="6"] &:nth-child(1),
					[data-theme-id="6"] &:nth-child(2),
					[data-theme-id="6"] &:nth-child(3),
					[data-theme-id="6"] &:nth-child(4),
					[data-theme-id="6"] &:nth-child(5),
					[data-theme-id="6"] &:nth-child(6) {
						button {
							background-color: $color-inch-worm;
						}
					}
				}

				button {
					text-indent: -999999px;
					border: 0;
					outline: 0;
					overflow: hidden;
					width: 21px;
					height: 4px;
					background-color: rgba($color-silver, 0.2);
					transition: background-color 0.3s ease;

					@include pc {
						width: 21 * $pc;
						height: 4 * $pc;
					}

					@include tablet {
						width: 21 * $tablet;
						height: 4 * $tablet;
					}

					@include mobile {
						width: 21 * $mobile;
						height: 4 * $mobile;
					}
				}
			}
		}
	}

	&--review {
		display: flex;
		flex-direction: column;
		justify-content: space-around;

		.slider {
			&__nav {
				display: flex;
				align-items: center;
				padding-left: 130px;

				@include pc {
					padding-left: 130 * $pc;
				}

				@include tablet {
					padding-left: 70 * $tablet;
				}

				@include mobile {
					padding-left: 0 * $mobile;
				}
				
				.nav {
					&__action {
						& > div {
							opacity: 0;
							height: 0;
							transition: opacity 300ms linear;

							&.slick-initialized {
								height: auto;
								opacity: 1;
							}
						}
					}
				}
			}

			&__media {
				position: relative;
				width: 100%;
				padding-top: percentage(9 / 16);
				box-shadow: 0 13px 34px 2px rgba(15, 35, 61, 0.23);
				background-position: 0 0;
				background-repeat: no-repeat;
				background-size: cover;

				&::after {
					display: none;
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					background: rgba($color-black, 0.05);
				}

				@include pc {
					box-shadow: 0 13 * $pc 34 * $pc 2 * $pc rgba(15, 35, 61, 0.23);
				}

				@include tablet {
					box-shadow: 0 13 * $tablet 34 * $tablet 2 * $tablet rgba(15, 35, 61, 0.23);
				}

				@include mobile {
					box-shadow: 0 13 * $mobile 34 * $mobile 2 * $mobile rgba(15, 35, 61, 0.23);

					&::after {
						display: none;
					}
				}

				iframe {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
				}
			}

			&__left {
				@include mobile {
					padding: 0 20 * $mobile;
				}
			}

			&__right {
				padding: 40px 25px 40px;

				@include pc {
					padding: 40 * $pc 25 * $pc 40 * $pc;
				}

				@include tablet {
					padding: 40 * $tablet 25 * $tablet 40 * $tablet;
				}

				@include mobile {
					padding: 0;
				}
			}

			&__controls {
				position: absolute;
				right: 7%;
				top: 50%;
				z-index: 50;
				flex-shrink: 0;
				align-items: center;
				justify-content: center;
				transform: rotate(90deg) translateY(-120%) translateX(-70%);
			}

			&__status {
				display: flex;
				min-width: 33px;
				flex-shrink: 0;
				font-weight: 700;
				font-size: 16px;
				margin-right: 13px;
				letter-spacing: 0.02px;
				line-height: 16px;
				color: $color-blue-zodiac;
				text-align: center;

				@include pc {
					min-width: 33 * $pc;
					font-size: 16 * $pc;
					margin-right: 13 * $pc;
					letter-spacing: 0.02 * $pc;
					line-height: 16 * $pc;
				}

				@include tablet {
					min-width: 33 * $tablet;
					font-size: 16 * $tablet;
					margin-right: 13 * $tablet;
					letter-spacing: 0.02 * $tablet;
					line-height: 16 * $tablet;
				}

				@include mobile {
					min-width: 33 * $mobile;
					font-size: 16 * $mobile;
					margin-right: 13 * $mobile;
					letter-spacing: 0.02 * $mobile;
					line-height: 16 * $mobile;
				}
			}

			&__pagination {
				display: flex;

				@include mobile {
					display: none;
				}

				ul {
					display: flex;
					flex-shrink: 0;
					list-style: none;
					margin: 0;
					padding: 0;
				}

				li {
					height: 4px;

					@include pc {
						height: 4 * $pc;
					}

					@include tablet {
						height: 4 * $tablet;
					}

					@include mobile {
						height: 4 * $mobile;
					}

					[data-theme-id="1"] &:nth-child(1) {
						button {
							background-color: $color-inch-worm;
						}
					}

					[data-theme-id="2"] &:nth-child(1),
					[data-theme-id="2"] &:nth-child(2), {
						button {
							background-color: $color-inch-worm;
						}
					}

					[data-theme-id="3"] &:nth-child(1),
					[data-theme-id="3"] &:nth-child(2),
					[data-theme-id="3"] &:nth-child(3), {
						button {
							background-color: $color-inch-worm;
						}
					}
				}

				button {
					text-indent: -999999px;
					border: 0;
					outline: 0;
					overflow: hidden;
					width: 21px;
					height: 4px;
					background-color: rgba($color-silver, 0.2);
					transition: background-color 0.3s ease;

					@include pc {
						width: 21 * $pc;
						height: 4 * $pc;
					}

					@include tablet {
						width: 21 * $tablet;
						height: 4 * $tablet;
					}

					@include mobile {
						width: 21 * $mobile;
						height: 4 * $mobile;
					}
				}
			}

			&__inner {
				opacity: 0;
				height: 0;
				transition: opacity 300ms linear;

				&.slick-initialized {
					height: auto;
					opacity: 1;
				}
			}
		}
	}

	&--certificate {
		opacity: 0;
		margin-bottom: 30px;
		height: 0;
		transition: opacity 300ms linear;

		&.slick-initialized {
			height: auto;
			opacity: 1;
		}
	}

	&__inner {
		width: 100%;
	}

	&__item {
		width: 100%;
	}

	&__content {
		display: flex;
		align-items: center;
		padding-left: 10px;

		@include pc {
			padding-left: 10 * $pc;
		}

		@include tablet {
			padding-left: 10 * $tablet;
		}

		@include mobile {
			flex-direction: column;
			padding-left: 0;
		}
	}

	&__left {
		position: relative;
		z-index: 10;
		flex-shrink: 0;
		margin-right: -180px;
		padding-left: 120px;
		width: 550px;

		@include pc {
			margin-right: -180 * $pc;
			padding-left: 120 * $pc;
			width: 550 * $pc;
		}

		@include tablet {
			margin-right: -240 * $tablet;
			padding-left: 60 * $tablet;
			width: 550 * $tablet;
		}

		@include mobile {
			margin-right: 0;
			margin-bottom: 20 * $mobile;
			padding-left: 0;
			width: 100%;
		}
	}

	&__right {
		max-width: 55%;
		width: 100%;

		@include mobile {
			max-width: 100%;
			width: 100%;
		}
	}

	&__suptitle {
		position: relative;
		margin: 0 0 30px;
		padding-left: 17px;
		font-weight: 800;
		font-size: 16px;
		letter-spacing: 0.03px;
		line-height: 21px;
		color: $color-white;

		@include pc {
			margin: 0 0 30 * $pc;
			padding-left: 17 * $pc;
			font-size: 16 * $pc;
			letter-spacing: 0.03 * $pc;
			line-height: 21 * $pc;
		}

		@include tablet {
			margin: 0 0 30 * $tablet;
			padding-left: 17 * $tablet;
			font-size: 16 * $tablet;
			letter-spacing: 0.03 * $tablet;
			line-height: 21 * $tablet;
		}

		@include mobile {
			margin: 0 0 30 * $mobile;
			padding-left: 17 * $mobile;
			font-size: 16 * $mobile;
			letter-spacing: 0.03 * $mobile;
			line-height: 21 * $mobile;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 3px;
			height: 21px;
			background-color: $color-inch-worm;

			@include pc {
				width: 3 * $pc;
				height: 21 * $pc;
			}

			@include tablet {
				width: 3 * $tablet;
				height: 21 * $tablet;
			}

			@include mobile {
				width: 3 * $mobile;
				height: 21 * $mobile;
			}
		}
	}

	&__title {
		position: relative;
		margin: 0 0 35px 0;
		font-weight: 700;
		font-size: 49px;
		letter-spacing: 0.21px;
		line-height: 49px;
		color: #fff;
		text-shadow: 0 0 10px rgba(15, 35, 61, 0.2);

		@include pc {
			margin: 0 0 35 * $pc 0;
			font-size: 49 * $pc;
			letter-spacing: 0.21 * $pc;
			line-height: 49 * $pc;
		}

		@include tablet {
			margin: 0 0 35 * $tablet 0;
			font-size: 49 * $tablet;
			letter-spacing: 0.21 * $tablet;
			line-height: 49 * $tablet;
		}

		@include mobile {
			margin: 0 0 20 * $mobile 0;
			font-size: 36 * $mobile;
			letter-spacing: 0.21 * $mobile;
			line-height: 36 * $mobile;
		}

		a {
			display: block;
			text-decoration: none;
			color: #fff;
			transition: all 300ms linear;

			&:hover {
				color: rgba($color-white, 0.7);
			}
		}
	}

	&__media {
		display: block;
		text-decoration: none;

		img {
			display: block;
			width: 100%;
		}
	}

	&__nav {
		overflow: hidden;
		width: 100%;
		padding: 50px 0 20px 10px;

		@include pc {
			padding: 100 * $pc 0 0 10 * $pc;
		}

		@include tablet {
			padding: 100 * $tablet 0 0 10 * $tablet;
		}

		@include mobile {
			padding: 10 * $mobile 0 0 10 * $mobile;
		}
	}

	&__controls {
		display: flex;
		justify-content: space-between;

		@include mobile {
			display: none;

			.section--certificate & {
				display: flex;
			}
		}

		&.is-chevron {
			margin: 0 auto;
			width: 64px;

			@include pc {
				width: 64 * $pc;
			}

			@include tablet {
				width: 64 * $tablet;
			}

			@include mobile {
				width: 64 * $mobile;
			}
		}
	}

	&__prev,
	&__next {
		cursor: pointer;

		.is-round & {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			width: 26px;
			height: 26px;
			background-color: $color-biscay;
			transition: background-color 0.3s linear;

			@include pc {
				width: 26 * $pc;
				height: 26 * $pc;
			}

			@include tablet {
				width: 26 * $tablet;
				height: 26 * $tablet;
			}

			@include mobile {
				width: 26 * $mobile;
				height: 26 * $mobile;
			}

			&:hover {
				background-color: lighten($color-biscay, 7%);
			}
		}

		.is-chevron & svg {
			width: 10px;
			height: 14px;
			fill: rgba($color-white, 0.9);

			@include pc {
				width: 10 * $pc;
				height: 14 * $pc;
			}

			@include tablet {
				width: 10 * $tablet;
				height: 14 * $tablet;
			}

			@include mobile {
				width: 10 * $mobile;
				height: 14 * $mobile;
			}
		}
	}
}