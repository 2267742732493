.order {
	display: flex;
	flex-wrap: wrap;

	&__left {
		width: 68%;

		@include mobile {
			flex-direction: column;
			order: 1;
			margin-bottom: 16 * $mobile;
			width: 100%;
		}

		.is-vertical & {
			flex-direction: column;
			order: 1;
			margin-bottom: 12 * $mobile;
			width: 100%;
		}
	}

	&__right {
		display: flex;
		align-items: center;
		margin: 0 auto;

		@include mobile {
			order: 3;
			padding-top: 24 * $mobile;
		}
		
		.is-vertical & {
			order: 3;
			padding-top: 24px;
		}
	}

	&__title {
		margin: 0 0 20px;
		font-weight: 400;
		font-size: 30px;
		letter-spacing: 0.03px;
		line-height: 30px;
		text-transform: uppercase;
		text-align: center;
		color: $color-white;

		@include pc {
			margin: 0 0 20 * $pc;
			font-size: 30 * $pc;
			letter-spacing: 0.03 * $pc;
			line-height: 30 * $pc;
		}

		@include tablet {
			margin: 0 0 20 * $tablet;
			font-size: 30 * $tablet;
			letter-spacing: 0.03 * $tablet;
			line-height: 30 * $tablet;
		}

		@include mobile {
			margin: 0 0 20 * $mobile;
			font-size: 30 * $mobile;
			letter-spacing: 0.03 * $mobile;
			line-height: 30 * $mobile;
		}

		span {
			font-weight: 700;
			color: $color-inch-worm;
		}
	}

	&__text {
		font-weight: 400;
		font-size: 14px;
		letter-spacing: 0.01px;
		line-height: 20px;
		text-align: center;
		color: $color-white;

		@include pc {
			font-size: 14 * $pc;
			letter-spacing: 0.01 * $pc;
			line-height: 20 * $pc;
		}

		@include tablet {
			font-size: 14 * $tablet;
			letter-spacing: 0.01 * $tablet;
			line-height: 20 * $tablet;
		}

		@include mobile {
			font-size: 14 * $mobile;
			letter-spacing: 0.01 * $mobile;
			line-height: 20 * $mobile;
		}

		span {
			font-weight: 700;
		}
	}

	&__bottom {
		display: flex;
		flex-shrink: 0;
		width: 100%;
		padding-top: 60px;

		@include pc {
			padding-top: 60 * $pc;
		}

		@include tablet {
			padding-top: 60 * $tablet;
		}

		@include mobile {
			order: 2;
			flex-direction: column;
			padding-top: 0 * $mobile;
		}
		
		.is-vertical & {
			order: 2;
			flex-direction: column;
			padding-top: 0;
		}

		&__left {
			display: flex;
			align-items: center;
			width: 68%;

			@include mobile {
				flex-direction: column;
				width: 100%;
			}

			.is-vertical & {
				flex-direction: column;
				width: 100%;
			}
		}

		&__right {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
			width: 23%;

			@include mobile {
				width: 100%;
			}

			.is-vertical & {
				width: 100%;
			}
		}
	}
}