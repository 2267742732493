.services-list {
	transition: opacity 0.3s linear;

	&:last-child {
		.services-list {
			&__inner {
				border: none;
			}
		}
	}

	&:hover {
		opacity: 0.8;

		.services-list {
			&__title {
				color: $color-lima;
			}
		}
	}

	&__inner {
		display: flex;
		align-items: flex-start;
		margin-bottom: 30px;
		padding-bottom: 30px;
		border-bottom: 1px solid rgba($color-alto, 0.75);

		@include pc {
			margin-bottom: 30 * $pc;
			padding-bottom: 30 * $pc;
		}

		@include tablet {
			margin-bottom: 20 * $tablet;
			padding-bottom: 20 * $tablet;
		}
		
		@include mobile {
			flex-direction: column;
			margin-bottom: 30 * $mobile;
			padding-bottom: 30 * $mobile;
		}
	}

	&__photo {
		padding-left: 40px;
		width: percentage(1 / 5);
		
		@include pc {
			padding-left: 40 * $pc;
		}

		@include tablet {
			padding-left: 40 * $tablet;
		}

		@include mobile {
			margin-bottom: 30 * $mobile;
			padding-left: 0;
			width: percentage(1 / 1);
		}

		img {
			display: block;
			width: 100%;
			border-radius: 10px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-left: 60px;
		padding-right: 120px;
		width: percentage(3 / 5);
		
		@include pc {
			padding-left: 60 * $pc;
			padding-right: 120 * $pc;
		}
		
		@include tablet {
			padding-left: 60 * $tablet;
			padding-right: 120 * $tablet;
		}

		@include mobile {
			padding-left: 0;
			padding-right: 0;
			width: percentage(1 / 1);
		}
	}

	&__details {
		display: flex;
		align-items: center;
		align-self: stretch;
		justify-content: space-between;
		width: percentage(1 / 5);

		@include mobile {
			width: percentage(1 / 1);
		}

		&__content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-grow: 1;
			padding-right: 20px;

			@include pc {
				padding-right: 20 * $pc;
			}

			@include tablet {
				padding-right: 20 * $tablet;
			}

			@include mobile {
				padding-right: 20 * $mobile;
			}
		}
	}

	&__detail {
		margin: 10px 0;

		@include pc {
			margin: 10 * $pc 0;
		}

		@include tablet {
			margin: 10 * $tablet 0;
		}

		@include mobile {
			margin: 10 * $mobile 0;
		}

		&__title {
			font-weight: 500;
			font-size: 13px;
			line-height: 21px;
			color: $color-blue-zodiac;

			@include pc {
				font-size: 13 * $pc;
				line-height: 21 * $pc;
			}

			@include tablet {
				font-size: 13 * $tablet;
				line-height: 21 * $tablet;
			}

			@include mobile {
				font-size: 13 * $mobile;
				line-height: 21 * $mobile;
			}
		}

		&__content {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__duration {
			font-weight: 300;
			font-size: 13px;
			line-height: 21px;
			color: $color-alto;

			@include pc {
				font-size: 13 * $pc;
				line-height: 21 * $pc;
			}

			@include tablet {
				font-size: 13 * $tablet;
				line-height: 21 * $tablet;
			}

			@include mobile {
				font-size: 13 * $mobile;
				line-height: 21 * $mobile;
			}
		}

		&__price {
			margin-left: auto;
			padding: 2px 8px;
			border-radius: 8px;
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
			background: $color-blue-zodiac-3;
			color: $color-inch-worm;

			@include pc {
				padding: 2 * $pc 8 * $pc;
				border-radius: 8 * $pc;
				font-size: 12 * $pc;
				line-height: 14 * $pc;
			}

			@include tablet {
				padding: 2 * $tablet 8 * $tablet;
				border-radius: 8 * $tablet;
				font-size: 12 * $tablet;
				line-height: 14 * $tablet;
			}

			@include mobile {
				padding: 2 * $mobile 8 * $mobile;
				border-radius: 8 * $mobile;
				font-size: 12 * $mobile;
				line-height: 14 * $mobile;
			}
		}
	}

	&__title {
		margin: 0 0 6px;
		font-weight: 700;
		font-size: 24px;
		line-height: 28px;
		color: $color-blue-zodiac;
		transition: color 0.3s linear;

		@include pc {
			margin: 0 0 6 * $pc;
			font-size: 24 * $pc;
			line-height: 28 * $pc;
		}

		@include tablet {
			margin: 0 0 6 * $tablet;
			font-size: 24 * $tablet;
			line-height: 28 * $tablet;
		}

		@include mobile {
			margin: 0 0 4 * $mobile;
			font-size: 24 * $mobile;
			line-height: 28 * $mobile;
		}

		span {
			display: block;
			font-weight: 700;
		}
	}

	&__date {
		position: relative;
		margin: 0 0 30px;
		padding-top: 12px;
		font-weight: 400;
		font-size: 12px;
		letter-spacing: 0.07px;
		line-height: 16px;
		color: $color-inch-worm;

		@include pc {
			margin: 0 0 20 * $pc;
			padding-top: 12 * $pc;
			font-size: 12 * $pc;
			letter-spacing: 0.07 * $pc;
			line-height: 16 * $pc;
		}

		@include tablet {
			margin: 0 0 20 * $tablet;
			padding-top: 12 * $tablet;
			font-size: 12 * $tablet;
			letter-spacing: 0.07 * $tablet;
			line-height: 16 * $tablet;
		}

		@include mobile {
			margin: 0 0 16 * $mobile;
			padding-top: 12 * $mobile;
			font-size: 12 * $mobile;
			letter-spacing: 0.07 * $mobile;
			line-height: 16 * $mobile;
		}

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 43px;
			height: 2px;
			background-color: $color-inch-worm;

			@include pc {
				width: 43 * $pc;
				height: 2 * $pc;
			}

			@include tablet {
				width: 43 * $tablet;
				height: 2 * $tablet;
			}

			@include mobile {
				width: 43 * $mobile;
				height: 2 * $mobile;
			}
		}
	}

	&__description {
		&, p {
			font-weight: 300;
			font-size: 14px;
			letter-spacing: 0.02px;
			line-height: 28px;
			color: $color-mine-shaft;

			@include pc {
				font-size: 14 * $pc;
				letter-spacing: 0.02 * $pc;
				line-height: 28 * $pc;
			}

			@include tablet {
				font-size: 14 * $tablet;
				letter-spacing: 0.02 * $tablet;
				line-height: 28 * $tablet;
			}

			@include mobile {
				font-size: 14 * $mobile;
				letter-spacing: 0.02 * $mobile;
				line-height: 28 * $mobile;
			}
		}
	}

	&__action {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding-top: 16px;
		text-decoration: none;
		font-size: 12px;
		line-height: 20px;
		color: $color-blue-zodiac;
		transition: color 0.3s ease;

		@include pc {
			padding-top: 16 * $pc;
			font-size: 12 * $pc;
			line-height: 20 * $pc;
		}

		@include tablet {
			padding-top: 16 * $tablet;
			font-size: 12 * $tablet;
			line-height: 20 * $tablet;
		}

		@include mobile {
			padding-top: 16 * $mobile;
			font-size: 12 * $mobile;
			line-height: 20 * $mobile;
		}

		svg {
			margin-left: 6px;
			width: 10px;
			height: 10px;
			fill: rgba($color-lima, 0.9);
			transition: fill 0.3s ease;

			@include pc {
				margin-left: 6 * $pc;
				width: 10 * $pc;
				height: 10 * $pc;
			}

			@include tablet {
				margin-left: 6 * $tablet;
				width: 10 * $tablet;
				height: 10 * $tablet;
			}

			@include mobile {
				margin-left: 6 * $mobile;
				width: 10 * $mobile;
				height: 10 * $mobile;
			}
		}

		&:hover {
			color: $color-lima;
		}
	}
}