.about-slider {
	&__inner {
		opacity: 0;
		height: 0;
		transition: opacity 300ms linear;

		&.slick-initialized {
			height: auto;
			opacity: 1;
		}
	}

	&__status {
		display: block;
		flex-shrink: 0;
		min-width: 34px;
		font-weight: 700;
		font-size: 11px;
		letter-spacing: 0.05px;
		line-height: 11px;
		text-align: center;
		color: $color-mine-shaft;

		@include pc {
			min-width: 34 * $pc;
			font-size: 11 * $pc;
			letter-spacing: 0.05 * $pc;
			line-height: 11 * $pc;
		}

		@include tablet {
			min-width: 34 * $tablet;
			font-size: 11 * $tablet;
			letter-spacing: 0.05 * $tablet;
			line-height: 11 * $tablet;
		}

		@include mobile {
			min-width: 34 * $mobile;
			font-size: 11 * $mobile;
			letter-spacing: 0.05 * $mobile;
			line-height: 11 * $mobile;
		}
	}

	&__controls {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 17px;
		width: 165px;
		height: 51px;
		background-color: $color-athens-gray;

		@include pc {
			padding: 10 * $pc 17 * $pc;
			width: 165 * $pc;
			height: 51 * $pc;
		}

		@include tablet {
			padding: 10 * $tablet 17 * $tablet;
			width: 165 * $tablet;
			height: 51 * $tablet;
		}

		@include mobile {
			padding: 10 * $mobile 17 * $mobile;
			width: 165 * $mobile;
			height: 51 * $mobile;
		}
	}

	&__prev,
	&__next {
		display: block;
		width: 16px;
		height: 16px;
		cursor: pointer;

		@include pc {
			width: 16 * $pc;
			height: 16 * $pc;
		}

		@include tablet {
			width: 16 * $tablet;
			height: 16 * $tablet;
		}

		@include mobile {
			width: 16 * $mobile;
			height: 16 * $mobile;
		}

		svg {
			width: 16px;
			height: 16px;
			transition: opacity 0.3s linear;

			@include pc {
				width: 16 * $pc;
				height: 16 * $pc;
			}

			@include tablet {
				width: 16 * $tablet;
				height: 16 * $tablet;
			}

			@include mobile {
				width: 16 * $mobile;
				height: 16 * $mobile;
			}

			&:hover {
				opacity: 0.75;
			}
		}
	}

	&__item {
		position: relative;
		padding-bottom: 21px;

		@include pc {
			padding-bottom: 21 * $pc;
		}

		@include tablet {
			padding-bottom: 21 * $tablet;
		}

		@include mobile {
			padding-bottom: 16 * $mobile;
		}
	}

	&__photo {
		img {
			display: block;
			width: 100%;
		}
	}

	&__title {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0;
		font-weight: 800;
		font-size: 48px;
		letter-spacing: 0.05px;
		line-height: 51px;
		text-transform: uppercase;
		text-align: center;
		color: $color-mine-shaft;

		@include pc {
			font-size: 48 * $pc;
			letter-spacing: 0.05 * $pc;
			line-height: 51 * $pc;
		}

		@include tablet {
			font-size: 48 * $tablet;
			letter-spacing: 0.05 * $tablet;
			line-height: 51 * $tablet;
		}

		@include mobile {
			font-size: 28 * $mobile;
			letter-spacing: 0.05 * $mobile;
			line-height: 32 * $mobile;
		}
	}
}