.person {
	position: relative;
	background-color: $color-white;
	overflow: hidden;

	&__label {
		position: absolute;
		right: 30px;
		top: 30px;
		padding: 6px 12px;
		border-radius: 12px;
		font-weight: 400;
		font-size: 16px;
		line-height: 16px;
		background: $color-blue-zodiac-3;
		color: $color-inch-worm;

		@include pc {
			right: 30 * $pc;
			top: 30 * $pc;
			padding: 6 * $pc 12 * $pc;
			border-radius: 12 * $pc;
			font-size: 16 * $pc;
			line-height: 16 * $pc;
		}

		@include tablet {
			right: 30 * $tablet;
			top: 30 * $tablet;
			padding: 6 * $tablet 12 * $tablet;
			border-radius: 12 * $tablet;
			font-size: 16 * $tablet;
			line-height: 16 * $tablet;
		}

		@include mobile {
			right: 30 * $mobile;
			top: 30 * $mobile;
			padding: 6 * $mobile 12 * $mobile;
			border-radius: 12 * $mobile;
			font-size: 16 * $mobile;
			line-height: 16 * $mobile;
		}

	}

	&__photo {
		transition: transform 0.3s ease;
		background-color: $color-blue-zodiac;
		width: 502px;
		height: 540px;

		@include pc {
			width: 502 * $pc;
			height: 540 * $pc;
		}

		@include tablet {
			width: 502 * $tablet;
			height: 540 * $tablet;
		}

		@include mobile {
			width: 340 * $mobile;
			height: 360 * $mobile;
		}

		img {
			display: block;
			width: 100%;
			height: 540px;
			object-fit: cover;
			font-family: 'object-fit: cover;';
			transition: opacity 0.3s ease 0.1s;

			@include pc {
				width: 502 * $pc;
				height: 540 * $pc;
			}
	
			@include tablet {
				width: 502 * $tablet;
				height: 540 * $tablet;
			}
	
			@include mobile {
				width: 340 * $mobile;
				height: 360 * $mobile;
				object-position: center 80%;
				font-family: 'object-fit: cover; object-position: center 80%;';
			}
		}
	}

	&__details {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		z-index: 1;
		display: flex;
		width: 100%;
		opacity: 0;
		transform: translateX(-100%);
		transition: opacity 0.3s ease 0.1s, transform 0.3s ease 0.1s;
	}

	&__description {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 40px 28px 80px;
		width: percentage(245 / 503);
		background-color: $color-athens-gray;
		
		@include pc {
			padding: 40 * $pc 28 * $pc 80 * $pc;
		}
		
		@include tablet {
			padding: 20 * $tablet 16 * $tablet 80 * $tablet;
		}
		
		@include mobile {
			width: 60%;
			padding: 12 * $mobile 10 * $mobile 52 * $mobile;
		}
	}

	&__video {
		overflow: hidden;
		position: relative;
		display: flex;
		z-index: -1;
		width: percentage(258 / 503);

		&__inner {
			opacity: 0;
			position: absolute;
			z-index: -100;
			width: 100%;
			padding-top: percentage(550 / 258);
			transition: all 300ms linear;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
			}

			.is-playing & {
				opacity: 1;
				z-index: 100;
			}
		}

		&__close {
			opacity: 0;
			position: absolute;
			right: 20px;
			top: 10px;
			z-index: 150;
			width: 16px;
			height: 16px;
			background: rgba($color-black, 0.005);
			cursor: pointer;

			@include pc {
				right: 20 * $pc;
				top: 10 * $pc;
				width: 16 * $pc;
				height: 16 * $pc;
			}

			@include tablet {
				right: 20 * $tablet;
				top: 10 * $tablet;
				width: 16 * $tablet;
				height: 16 * $tablet;
			}

			@include mobile {
				right: 10 * $mobile;
				top: 10 * $mobile;
				width: 16 * $mobile;
				height: 16 * $mobile;
			}
	
			svg {
				width: 16px;
				height: 16px;
				fill: rgba($color-white, 0.2);
				transition: fill 300ms linear;

				@include pc {
					width: 16 * $pc;
					height: 16 * $pc;
				}

				@include tablet {
					width: 16 * $tablet;
					height: 16 * $tablet;
				}

				@include mobile {
					width: 16 * $mobile;
					height: 16 * $mobile;
				}
			}

			&:hover {
				svg {
					fill: $color-white;
				}
			}
		}
	}

	&__name {
		margin: 0 0 10px;
		font-weight: 800;
		font-size: 21px;
		letter-spacing: 0.04px;
		line-height: 24px;
		color: $color-mine-shaft;

		@include pc {
			margin: 0 0 10 * $pc;
			font-size: 21 * $pc;
			letter-spacing: 0.04 * $pc;
			line-height: 24 * $pc;
		}

		@include tablet {
			margin: 0 0 10 * $tablet;
			font-size: 21 * $tablet;
			letter-spacing: 0.04 * $tablet;
			line-height: 24 * $tablet;
		}

		@include mobile {
			margin: 0 0 6 * $mobile;
			font-size: 16 * $mobile;
			letter-spacing: 0.04 * $mobile;
			line-height: 18 * $mobile;
		}
	}

	&__position {
		position: relative;
		margin-bottom: 20px;
		padding-bottom: 12px;
		font-weight: 400;
		font-size: 14px;
		letter-spacing: 0.06px;
		line-height: 16px;
		color: rgba($color-lima, 0.9);

		@include pc {
			margin-bottom: 20 * $pc;
			padding-bottom: 12 * $pc;
			font-size: 14 * $pc;
			letter-spacing: 0.06 * $pc;
			line-height: 16 * $pc;
		}

		@include tablet {
			margin-bottom: 10 * $tablet;
			padding-bottom: 12 * $tablet;
			font-size: 14 * $tablet;
			letter-spacing: 0.06 * $tablet;
			line-height: 16 * $tablet;
		}

		@include mobile {
			margin-bottom: 6 * $mobile;
			padding-bottom: 4 * $mobile;
			font-size: 12 * $mobile;
			letter-spacing: 0.06 * $mobile;
			line-height: 14 * $mobile;
		}

		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 42px;
			height: 3px;
			background-color: $color-inch-worm;

			@include pc {
				width: 42 * $pc;
				height: 3 * $pc;
			}

			@include tablet {
				width: 42 * $tablet;
				height: 3 * $tablet;
			}

			@include mobile {
				width: 42 * $mobile;
				height: 2 * $mobile;
			}
		}
	}

	&__stats {
		display: flex;
		margin-bottom: 35px;
		flex-shrink: 0;

		@include pc {
			margin-bottom: 25 * $pc;
		}

		@include tablet {
			margin-bottom: 16 * $tablet;
		}

		@include mobile {
			margin-bottom: 6 * $mobile;
		}
	}

	&__experience,
	&__cases {
		width: 50%;
		font-weight: 600;
		font-size: 12px;
		letter-spacing: 0.02px;
		line-height: 14px;
		color: $color-mine-shaft;

		@include pc {
			font-size: 12 * $pc;
			letter-spacing: 0.02 * $pc;
			line-height: 14 * $pc;
		}

		@include tablet {
			font-size: 12 * $tablet;
			letter-spacing: 0.02 * $tablet;
			line-height: 14 * $tablet;
		}

		@include mobile {
			font-size: 10 * $mobile;
			letter-spacing: 0.02 * $mobile;
			line-height: 12 * $mobile;
		}

		span {
			display: block;
			margin: 0 0 8px;
			font-weight: 700;
			font-size: 29px;
			letter-spacing: 0.05px;
			line-height: 30px;
			color: $color-mine-shaft;

			@include pc {
				margin: 0 0 8 * $pc;
				font-size: 29 * $pc;
				letter-spacing: 0.05 * $pc;
				line-height: 30 * $pc;
			}

			@include tablet {
				margin: 0 0 8 * $tablet;
				font-size: 29 * $tablet;
				letter-spacing: 0.05 * $tablet;
				line-height: 30 * $tablet;
			}

			@include mobile {
				margin: 0 0 8 * $mobile;
				font-size: 16 * $mobile;
				letter-spacing: 0.05 * $mobile;
				line-height: 18 * $mobile;
			}
		}
	}

	&__location {
		margin-bottom: 36px;
		font-size: 12px;
		letter-spacing: 0.02px;
		line-height: 19px;
		color: $color-mine-shaft;

		@include pc {
			margin-bottom: 36 * $pc;
			font-size: 12 * $pc;
			letter-spacing: 0.02 * $pc;
			line-height: 19 * $pc;
		}

		@include tablet {
			margin-bottom: 16 * $tablet;
			font-size: 12 * $tablet;
			letter-spacing: 0.02 * $tablet;
			line-height: 19 * $tablet;
		}

		@include mobile {
			margin-bottom: 6 * $mobile;
			font-size: 10 * $mobile;
			letter-spacing: 0.02 * $mobile;
			line-height: 12 * $mobile;
		}
	}

	&__worktime {
		margin-bottom: 10px;

		&__title {
			margin: 0 0 14px;
			font-weight: 800;
			font-size: 12px;
			letter-spacing: 0.02px;
			line-height: 14px;
			color: $color-mine-shaft;

			@include pc {
				margin: 0 0 14 * $pc;
				font-size: 12 * $pc;
				letter-spacing: 0.02 * $pc;
				line-height: 14 * $pc;
			}

			@include tablet {
				margin: 0 0 8 * $tablet;
				font-size: 12 * $tablet;
				letter-spacing: 0.02 * $tablet;
				line-height: 14 * $tablet;
			}

			@include mobile {
				margin: 0 0 6 * $mobile;
				font-size: 10 * $mobile;
				letter-spacing: 0.02 * $mobile;
				line-height: 12 * $mobile;
			}

			svg {
				margin-right: 11px;
				width: 11px;
				height: 11px;
				fill: rgba($color-black, 0.4);

				@include pc {
					margin-right: 11 * $pc;
					width: 11 * $pc;
					height: 11 * $pc;
				}

				@include tablet {
					margin-right: 11 * $tablet;
					width: 11 * $tablet;
					height: 11 * $tablet;
				}

				@include mobile {
					margin-right: 6 * $mobile;
					width: 11 * $mobile;
					height: 11 * $mobile;
				}
			}
		}

		&__week {
			list-style: none;
			margin: 0 0 35px;
			padding: 0 0 0 22px;

			@include pc {
				margin: 0 0 35 * $pc;
				padding: 0 0 0 22 * $pc;
			}

			@include tablet {
				margin: 0 0 16 * $tablet;
				padding: 0 0 0 22 * $tablet;
			}

			@include mobile {
				margin: 0 0 6 * $mobile;
				padding: 0 0 0 16 * $mobile;
			}
		}

		&__day {
			font-weight: 400;
			font-size: 11px;
			letter-spacing: 0.02px;
			line-height: 19px;
			color: rgba($color-mine-shaft, 0.9);

			@include pc {
				font-size: 11 * $pc;
				letter-spacing: 0.02 * $pc;
				line-height: 19 * $pc;
			}

			@include tablet {
				font-size: 11 * $tablet;
				letter-spacing: 0.02 * $tablet;
				line-height: 19 * $tablet;
			}

			@include mobile {
				font-size: 10 * $mobile;
				letter-spacing: 0.02 * $mobile;
				line-height: 12 * $mobile;
			}
		}
	}

	&__action {
		position: absolute;
		left: 20px;
		bottom: 16px;
		z-index: 200;

		@include pc {
			left: 16 * $pc;
			bottom: 16 * $pc;
		}

		@include tablet {
			left: 16 * $tablet;
			bottom: 16 * $tablet;
		}

		@include mobile {
			left: 16 * $mobile;
			bottom: 16 * $mobile;
		}

		.btn {
			white-space: nowrap;
		}
	}

	&__play-video {
		position: relative;
		z-index: 10;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: auto;
		cursor: pointer;
		transition: all 300ms linear;

		.is-playing & {
			opacity: 0;
		}

		&:hover {
			opacity: 0.8;
		}

		&__icon {
			margin-bottom: 10px;

			@include pc {
				margin-bottom: 10 * $pc;
			}

			@include tablet {
				margin-bottom: 10 * $tablet;
			}

			@include mobile {
				margin-bottom: 10 * $mobile;
			}

			svg {
				width: 34px;
				height: 32px;
				fill: $color-white;

				@include pc {
					width: 34 * $pc;
					height: 32 * $pc;
				}

				@include tablet {
					width: 34 * $tablet;
					height: 32 * $tablet;
				}

				@include mobile {
					width: 34 * $mobile;
					height: 32 * $mobile;
				}
			}
		}

		&__text {
			font-weight: 700;
			font-size: 11px;
			letter-spacing: 0.02px;
			line-height: 14px;
			color: $color-white;

			@include pc {
				font-size: 11 * $pc;
				letter-spacing: 0.02 * $pc;
				line-height: 14 * $pc;
			}

			@include tablet {
				font-size: 11 * $tablet;
				letter-spacing: 0.02 * $tablet;
				line-height: 14 * $tablet;
			}

			@include mobile {
				font-size: 11 * $mobile;
				letter-spacing: 0.02 * $mobile;
				line-height: 14 * $mobile;
			}
		}
	}

	&__corner-action {
		position: absolute;
		right: 20px;
		bottom: 20px;
		z-index: 150;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;

		@include pc {
			right: 20 * $pc;
			bottom: 16 * $pc;
		}

		@include tablet {
			right: 20 * $tablet;
			bottom: 16 * $tablet;
			font-size: 0;
			padding: 8 * $tablet 10 * $tablet 8 * $tablet 5 * $tablet;
		}

		@include mobile {
			right: 20 * $mobile;
			bottom: 16 * $mobile;
			padding: 8 * $mobile 10 * $mobile 8 * $mobile 5 * $mobile;
		}

		svg {
			margin-left: 15px;
			width: 17px;
			height: 11px;
			fill: $color-white;
			transition: fill 300ms linear;

			@include pc {
				margin-left: 15 * $pc;
				width: 17 * $pc;
				height: 11 * $pc;
			}

			@include tablet {
				margin-left: 0;
				width: 17 * $tablet;
				height: 11 * $tablet;
			}

			@include mobile {
				margin-left: 0;
				width: 17 * $mobile;
				height: 11 * $mobile;
			}
		}

		.is-playing & {
			svg {
				fill: rgba($color-white, 0.2);
			}

			&:hover {
				svg {
					fill: $color-white;
				}
			}
		}
	}

	&.is-playing,
	&:hover {
		.person {
			&__details {
				opacity: 1;
				transform: translateX(0);
				transition: transform 0.3s ease;
			}
	
			&__photo {
				transform: translateX(30%);
				transition: transform 0.45s ease;
	
				img {
					opacity: 0.54;
				}
			}
		}
	}

	
	&.is-playing {
		.person {
			&__video {
				z-index: 10;

				&::before {
					opacity: 0;
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					z-index: 120;
					width: 100%;
					height: 10%;
					background: linear-gradient(to top, rgba($color-blue-zodiac, 1) 0%, $color-blue-zodiac 100%);
					transform: translateY(-100%);
					transition: all 0.3s linear;
				}

				&::after {
					opacity: 0;
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					z-index: 120;
					width: 100%;
					height: 10%;
					background: linear-gradient(to bottom, rgba($color-blue-zodiac, 1) 0%, $color-blue-zodiac 100%);
					transform: translateY(100%);
					transition: all 0.3s linear;
				}

				&:hover {
					&::before {
						opacity: 1;
						transform: translateY(0);
					}
					
					&::after {
						opacity: 1;
						transform: translateY(0);
					}
				}

				&__close {
					opacity: 1;
				}
			}
		}
	}
}


