.btn {
	display: inline-block;
	padding: 10px 20px;
	background-color: $color-inch-worm;
	font-weight: 600;
	font-size: 11px;
	letter-spacing: 0.48px;
	line-height: 21px;
	text-decoration: none;
	text-transform: uppercase;
	text-align: center;
	color: $color-black;
	transition: all 0.3s ease;

	@include pc {
		padding: 10 * $pc 20 * $pc;
		font-size: 11 * $pc;
		letter-spacing: 0.48 * $pc;
		line-height: 21 * $pc;
	}

	@include tablet {
		padding: 10 * $tablet 20 * $tablet;
		font-size: 11 * $tablet;
		letter-spacing: 0.48 * $tablet;
		line-height: 21 * $tablet;
	}

	@include mobile {
		padding: 10 * $mobile 20 * $mobile;
		font-size: 11 * $mobile;
		letter-spacing: 0.48 * $mobile;
		line-height: 21 * $mobile;
	}

	&:hover {
		background-color: darken($color-inch-worm, 5%);
	}

	&--action {
		padding: 5px 25px;
		background: $color-inch-worm;
		color: $color-madison;

		@include pc {
			padding: 5 * $pc 20 * $pc;
		}

		@include tablet {
			padding: 5 * $tablet 20 * $tablet;
		}

		@include mobile {
			padding: 5 * $mobile 20 * $mobile;
		}
	}

	&--bordered {
		background: transparent;
		border: 2px solid $color-white;
		color: $color-white;
		transition: opacity 0.3s linear;

		@include pc {
			border-width: 2 * $pc;
			padding: 8 * $pc 20 * $pc;
		}

		@include tablet {
			border-width: 2 * $tablet;
			padding: 8 * $tablet 20 * $tablet;
		}

		@include mobile {
			border-width: 2 * $mobile;
			padding: 8 * $mobile 20 * $mobile;
		}

		&:hover {
			opacity: 0.8;
			background: transparent;
		}

	}
}