.section {

	&__title {
		margin: 0 0 31px;
		padding-left: 40px;
		font-weight: 500;
		font-size: 31px;
		letter-spacing: 0.05px;
		line-height: 31px;
		color: $color-silver;

		@include pc {
			margin: 0 0 31 * $pc;
			padding-left: 40 * $pc;
			font-size: 31 * $pc;
			letter-spacing: 0.05 * $pc;
			line-height: 31 * $pc;
		}

		@include tablet {
			margin: 0 0 31 * $tablet;
			padding-left: 40 * $tablet;
			font-size: 31 * $tablet;
			letter-spacing: 0.05 * $tablet;
			line-height: 31 * $tablet;
		}

		@include mobile {
			margin: 0 0 31 * $mobile 0;
			padding-left: 20 * $mobile;
			font-size: 31 * $mobile;
			letter-spacing: 0.05 * $mobile;
			line-height: 31 * $mobile;
		}
	}

	&__screen {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		// min-height: 100vh;

		@include mobile {
			min-height: auto;
		}

		.is-margin-ba {
			margin-bottom: auto;
		}
	}

	&__inner {
		margin: 0 auto;
		padding: 25px $gutter-md;
		max-width: $container-md;
		width: 100%;

		@include pc {
			padding: 25 * $pc $gutter-lg * $pc;
			max-width: $container-lg;
		}

		@include tablet {
			padding: 25 * $tablet $gutter-lg * $tablet;
			max-width: $container-lg;
		}

		@include mobile {
			padding: 25 * $mobile 20 * $mobile;
			max-width: $container-lg;
		}

		p {
			margin: 0 0 16px;
			font-weight: 300;
			font-size: 14px;
			letter-spacing: 0.02px;
			line-height: 23px;
			color: $color-mine-shaft;

			&:focus {
				outline: none;
			}

			@include pc {
				margin: 0 0 16 * $pc;
				font-size: 14 * $pc;
				letter-spacing: 0.02 * $pc;
				line-height: 23 * $pc;
			}

			@include tablet {
				margin: 0 0 16 * $tablet;
				font-size: 14 * $tablet;
				letter-spacing: 0.02 * $tablet;
				line-height: 23 * $tablet;
			}

			@include mobile {
				margin: 0 0 16 * $mobile;
				font-size: 14 * $mobile;
				letter-spacing: 0.02 * $mobile;
				line-height: 23 * $mobile;
			}
		}

		ul, ol, dd {
			margin: 0 0 16px;
			font-weight: 300;
			font-size: 14px;
			letter-spacing: 0.02px;
			line-height: 23px;
			color: $color-mine-shaft;
			
			@include pc {
				margin: 0 0 16 * $pc;
				font-size: 14 * $pc;
				letter-spacing: 0.02 * $pc;
				line-height: 23 * $pc;
			}

			@include tablet {
				margin: 0 0 16 * $tablet;
				font-size: 14 * $tablet;
				letter-spacing: 0.02 * $tablet;
				line-height: 23 * $tablet;
			}

			@include mobile {
				margin: 0 0 16 * $mobile;
				font-size: 14 * $mobile;
				letter-spacing: 0.02 * $mobile;
				line-height: 23 * $mobile;
			}
		}
	}

	&--fullwide {
		background-color: $color-athens-gray;

		&.is-no-padding {
			.section {
				&__inner {
					padding-top: 0;
					padding-bottom: 0;
				}
			}
		}

		.section {
			&__inner {
				padding: 40px 35px 35px 66px;

				@include pc {
					padding: 40 * $pc 35 * $pc 35 * $pc 66 * $pc;
				}

				@include tablet {
					padding: 40 * $tablet 35 * $tablet 35 * $tablet 66 * $tablet;
				}

				@include mobile {
					padding: 24 * $mobile 20 * $mobile;
				}

				p:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&--carouesl-news {
		background-color: $color-athens-gray;
	}

	&--exhortation {
		.section {
			&__inner {
				max-width: 80%;
				padding-top: 158px;
				padding-bottom: 214px;

				@include pc {
					padding-top: 158 * $pc;
					padding-bottom: 214 * $pc;
				}

				@include tablet {
					padding-top: 158 * $tablet;
					padding-bottom: 214 * $tablet;
				}

				@include mobile {
					padding-top: 40 * $mobile;
					padding-bottom: 40 * $mobile;
					max-width: 100%;
				}
			}
		}
	}

	&--certificate {
		overflow: hidden;

		.section {
			&__screen {
				justify-content: flex-start;
				padding-top: 130px;
				padding-bottom: 170px;

				@include pc {
					padding-top: 40 * $pc;
					padding-bottom: 80 * $pc;
				}

				@include tablet {
					padding-top: 40 * $tablet;
					padding-bottom: 80 * $tablet;
				}

				@include mobile {
					padding-top: 40 * $mobile;
					padding-bottom: 80 * $mobile;
				}
			}

			&__inner {
				padding: 0px 35px 24px 66px;

				@include pc {
					padding: 0 * $pc 35 * $pc 24 * $pc 66 * $pc;
				}

				@include tablet {
					padding: 0 * $tablet 35 * $tablet 24 * $tablet 66 * $tablet;
				}

				@include mobile {
					padding: 24 * $mobile 20 * $mobile;
				}
			}

			&__title {
				margin: 0 0 16px;
				padding: 0;
				font-weight: 800;
				font-size: 25px;
				letter-spacing: 0.04px;
				line-height: 25px;
				color: $color-biscay;

				@include pc {
					margin: 0 0 16 * $pc;
					padding: 0;
					font-size: 25 * $pc;
					letter-spacing: 0.04 * $pc;
					line-height: 25 * $pc;
				}

				@include tablet {
					margin: 0 0 16 * $tablet;
					padding: 0;
					font-size: 25 * $tablet;
					letter-spacing: 0.04 * $tablet;
					line-height: 25 * $tablet;
				}

				@include mobile {
					margin: 0 0 16 * $mobile;
					padding: 0;
					font-size: 25 * $mobile;
					letter-spacing: 0.04 * $mobile;
					line-height: 25 * $mobile;
				}
			}

			&__description {
				margin-bottom: 64px;
				font-weight: 300;
				font-size: 16px;
				letter-spacing: 0.09px;
				line-height: 25px;
				color: $color-mine-shaft;

				@include pc {
					margin-bottom: 64 * $pc;
					font-size: 16 * $pc;
					letter-spacing: 0.09 * $pc;
					line-height: 25 * $pc;
				}

				@include tablet {
					margin-bottom: 64 * $tablet;
					font-size: 16 * $tablet;
					letter-spacing: 0.09 * $tablet;
					line-height: 25 * $tablet;
				}

				@include mobile {
					margin-bottom: 32 * $mobile;
					font-size: 16 * $mobile;
					letter-spacing: 0.09 * $mobile;
					line-height: 25 * $mobile;
				}
			}

			&__top {
				display: flex;
				align-items: center;
				
				&__left {
					width: calc(100% - 300px);

					@include pc {
						width: 80%;
					}

					@include tablet {
						width: 80%;
					}

					@include mobile {
						width: 80%;
					}
				}

				&__right {
					margin-left: auto;
				}
			}
		}
	}

	&--service {
		.section {
			&__screen {
				padding-top: 30px;
				padding-bottom: 70px;

				@include pc {
					padding-top: 30 * $pc;
					padding-bottom: 30 * $pc;
				}

				@include tablet {
					padding-top: 30 * $tablet;
					padding-bottom: 30 * $tablet;
				}

				@include mobile {
					padding-top: 30 * $mobile;
					padding-bottom: 30 * $mobile;
				}
			}

			&__title {
				margin: 0 0 31px;
				padding-left: 40px;
				font-weight: 800;
				font-size: 31px;
				letter-spacing: 0.05px;
				line-height: 31px;
				color: $color-biscay;

				@include pc {
					margin: 0 0 31 * $pc;
					padding-left: 40 * $pc;
					font-size: 31 * $pc;
					letter-spacing: 0.05 * $pc;
					line-height: 31 * $pc;
				}

				@include tablet {
					margin: 0 0 31 * $tablet;
					padding-left: 40 * $tablet;
					font-size: 31 * $tablet;
					letter-spacing: 0.05 * $tablet;
					line-height: 31 * $tablet;
				}

				@include mobile {
					margin: 31 * $mobile 0;
					padding-left: 20 * $mobile;
					font-size: 31 * $mobile;
					letter-spacing: 0.05 * $mobile;
					line-height: 31 * $mobile;
				}
			}

			&__inner {
				padding: 20px 0;

				@include pc {
					padding: 0 20 * $pc;
				}

				@include tablet {
					padding: 0 20 * $tablet;
				}

				@include mobile {
					padding: 0 20 * $mobile;
				}
			}
		}
	}

	&--alt,
	&--order,
	&--review {
		position: relative;
		background-color: $color-blue-zodiac;

		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			width: percentage(435 / 1250);
			height: 100%;
			background: $color-white;

			@include mobile {
				display: none;
			}
		}

		.section {
			&__inner {
				position: relative;
				z-index: 10;
			}
		}
	}

	&--order {
		padding: 35px 0;

		@include pc {
			padding: 35 * $pc 0;
		}

		@include tablet {
			padding: 35 * $tablet 0;
		}

		@include tablet {
			padding: 35 * $tablet 0;
		}

		@include mobile {
			padding: 0;

			&::after {
				display: none;
			}
		}

		&.is-vertical {
			padding: 0;

			&::after {
				display: none;
			}
		}
	}

	&--review {
		.section {
			&__inner {
				@include mobile {
					padding: 25 * $mobile 0;
				}
			}
		}
	}

	&--about,
	&--card,
	&--contacts {
		background-color: $color-athens-gray;

		.section {
			&__screen {
				padding: 23px 0;

				@include pc {
					padding: 23 * $pc 0;
				}

				@include tablet {
					padding: 23 * $tablet 0;
				}

				@include mobile {
					padding: 23 * $mobile 0;
				}
			}
		}
	}
	
	&--about {
		.section {
			&__screen {
				padding: 0;

				@include pc {
					padding: 0;
				}

				@include tablet {
					padding: 0;
				}

				@include mobile {
					padding: 0;
				}
			}

			&__inner {
				padding: 0 35px 0 66px;

				@include pc {
					padding: 0 35 * $pc 0 66 * $pc;
				}

				@include tablet {
					padding: 0 35 * $tablet 0 66 * $tablet;
				}

				@include mobile {
					padding: 0 20 * $mobile;
				}

				p:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	
	&--contacts {
		.section {
			&__inner {
				padding: 77px 0 95px;

				@include pc {
					padding: 77 * $pc 0 95 * $pc;
				}

				@include tablet {
					padding: 77 * $tablet 0 95 * $tablet;
				}

				@include mobile {
					padding: 77 * $mobile 0 15 * $mobile;
				}
			}
		}
	}
	
	&--card {
		.section {
			&__inner {
				padding-top: 30px;
				padding-bottom: 30px;

				@include pc {
					padding: 30 * $pc $gutter-lg * 2 * $pc 30 * $pc;
				}

				@include tablet {
					padding: 30 * $tablet $gutter-lg * 2 * $tablet 30 * $tablet;
				}

				@include mobile {
					padding: 30 * $mobile 20 * $mobile;
				}
			}
		}

		.card {
			&__description {
				&, p {
					height: auto;
				}
			}
		}
	}

	&--specialists {
		position: relative;
		background-color: lighten($color-blue-zodiac, 1%);
	
		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 125px;
			background: $color-white;

			@include pc {
				height: 125 * $pc;
			}

			@include tablet {
				height: 125 * $tablet;
			}

			@include mobile {
				height: 125 * $mobile;
			}
		}

		.section {
			&__screen {
				padding: 23px 0 0;

				@include pc {
					padding: 23 * $pc 0 0;
				}

				@include tablet {
					padding: 23 * $tablet 0 0;
				}

				@include mobile {
					padding: 23 * $mobile 0 0;
				}
			}

			&__inner {
				padding: 20px 44px 0;

				@include pc {
					padding: 20 * $pc 44 * $pc 0;
				}

				@include tablet {
					padding: 20 * $tablet 44 * $tablet 0;
				}

				@include mobile {
					padding: 20 * $mobile 20 * $mobile 0;
				}
			}

			&__title {
				margin: 25px 0 40px 7%;
				font-weight: 300;
				font-size: 25px;
				line-height: 28px;
				color: $color-white;

				@include pc {
					margin: 25 * $pc 0 40 * $pc 7%;
					font-size: 25 * $pc;
					line-height: 28 * $pc;
				}

				@include tablet {
					margin: 25 * $tablet 0 40 * $tablet 7%;
					font-size: 25 * $tablet;
					line-height: 28 * $tablet;
				}

				@include mobile {
					margin: 25 * $mobile 0 40 * $mobile;
					font-size: 25 * $mobile;
					line-height: 28 * $mobile;
				}

				span {
					font-weight: 700;
				}
			}
		}
	}

	&--scheme {
		overflow: hidden;
		background-color: $color-bunting;

		.section {
			&__screen {
				padding: 106px $gutter-md * 2 116px;

				@include pc {
					padding: 60 * $pc $gutter-lg * 2 * $pc 40 * $pc;
				}

				@include tablet {
					padding: 60 * $tablet $gutter-lg * 2 * $tablet 40 * $tablet;
				}

				@include mobile {
					padding: 60 * $mobile 5 * $mobile;
				}
			}

			&__inner {
				@include mobile {
					padding: 0;
				}
			}

			&__title {
				margin: 0 0 16px;
				font-weight: 800;
				font-size: 25px;
				letter-spacing: 0.04px;
				line-height: 25px;
				color: $color-white;
				text-align: center;

				@include pc {
					margin: 0 0 16 * $pc;
					font-size: 25 * $pc;
					letter-spacing: 0.04 * $pc;
					line-height: 25 * $pc;
				}

				@include tablet {
					margin: 0 0 16 * $tablet;
					font-size: 25 * $tablet;
					letter-spacing: 0.04 * $tablet;
					line-height: 25 * $tablet;
				}

				@include mobile {
					margin: 0 0 16 * $mobile;
					font-size: 25 * $mobile;
					letter-spacing: 0.04 * $mobile;
					line-height: 25 * $mobile;
				}
			}

			&__description {
				margin-bottom: 64px;
				font-weight: 300;
				font-size: 16px;
				letter-spacing: 0.09px;
				line-height: 25px;
				color: $color-white;
				text-align: center;

				@include pc {
					margin-bottom: 64 * $pc;
					font-size: 16 * $pc;
					letter-spacing: 0.09 * $pc;
					line-height: 25 * $pc;
				}

				@include tablet {
					margin-bottom: 64 * $tablet;
					font-size: 16 * $tablet;
					letter-spacing: 0.09 * $tablet;
					line-height: 25 * $tablet;
				}

				@include mobile {
					margin-bottom: 32 * $mobile;
					font-size: 16 * $mobile;
					letter-spacing: 0.09 * $mobile;
					line-height: 25 * $mobile;
				}
			}
		}
	}

	&--news-list {
		.section {
			&__title {
				margin: 0 0 31px;
				padding-left: 40px;
				font-weight: 500;
				font-size: 31px;
				letter-spacing: 0.05px;
				line-height: 31px;
				color: $color-silver;

				@include pc {
					margin: 0 0 31 * $pc;
					padding-left: 40 * $pc;
					font-size: 31 * $pc;
					letter-spacing: 0.05 * $pc;
					line-height: 31 * $pc;
				}

				@include tablet {
					margin: 0 0 31 * $tablet;
					padding-left: 40 * $tablet;
					font-size: 31 * $tablet;
					letter-spacing: 0.05 * $tablet;
					line-height: 31 * $tablet;
				}

				@include mobile {
					margin: 0 0 31 * $mobile 0;
					padding-left: 20 * $mobile;
					font-size: 31 * $mobile;
					letter-spacing: 0.05 * $mobile;
					line-height: 31 * $mobile;
				}
			}
		}
	}

	&--success {
		background-color: $color-blue-zodiac;

		.section {
			&__title {
				color: $color-white;
			}

			&__screen {
				padding: 120px 0;

				@include pc {
					padding: 120 * $pc 0;
				}

				@include tablet {
					padding: 120 * $tablet 0;
				}

				@include mobile {
					padding: 120 * $mobile 0;
				}
			}
		}
	}
}