.hamburger {
	position: relative;
	display: none;
	padding-left: 0;
	padding-right: 0;
	
	@include mobile {
		display: flex;
	}

	&-inner {
		opacity: 1;
		transition-property: background-color, opacity, transform;
	}

	&:hover {
		.hamburger-inner {
			background-color: $color-white;
		}
	}
}

@include mobile {
	$hamburger-padding-x                       : 10 * $mobile;
	$hamburger-padding-y                       : 0;
	$hamburger-layer-width                     : 28px;
	$hamburger-layer-height                    : 3px;
	$hamburger-layer-spacing                   : 6px;
	$hamburger-layer-border-radius             : 3px;
	$hamburger-layer-color                     : $color-white;
	
	.hamburger-box {
		width: $hamburger-layer-width;
		height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
		display: inline-block;
		position: relative;
	}

	.hamburger-inner {
		opacity: 1;
		display: block;
		top: 50%;
		margin-top: $hamburger-layer-height / -2;

		&,
		&::before,
		&::after {
			width: $hamburger-layer-width;
			height: $hamburger-layer-height;
			background-color: $hamburger-layer-color;
			border-radius: $hamburger-layer-border-radius;
			position: absolute;
			transition-property: transform;
			transition-duration: 0.15s;
			transition-timing-function: ease;
		}

		&::before,
		&::after {
			content: "";
			display: block;
		}

		&::before {
			top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
		}

		&::after {
			bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
		}
	}
}