.modal {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: rgba($color-black, 0.85);

	&--white {
		.modal {
			&__inner {
				background: $color-white;
			}

			&__close {
				&__inner {
					background: rgba($color-white, 0.005);
				}
		
				svg {
					fill: rgba($color-blue-zodiac-3, 0.15);
				}
		
				&:hover svg {
					fill: $color-blue-zodiac-3;
				}
			}
		}
	}

	&--lg {
		.modal {
			&__inner {
				max-width: 70%;

				@include mobile {
					max-width: 92%;
				}
			}
		}
	}

	&__inner {
		position: relative;
		margin: 10vh auto;
		border-radius: 10px;
		padding: 30px;
		width: 100%;
		max-width: 50%;
		max-height: 80vh;
		overflow-y: auto;
		background: $color-blue-zodiac;
		box-shadow: 0 0 16px $color-blue-zodiac-3;

		@include pc {
			border-radius: 10 * $pc;
			padding: 30 * $pc;
			box-shadow: 0 0 16 * $pc $color-blue-zodiac-3;
		}

		@include tablet {
			max-width: 60%;
			border-radius: 10 * $tablet;
			padding: 30 * $tablet;
			box-shadow: 0 0 16 * $tablet $color-blue-zodiac-3;
		}

		@include mobile {
			max-width: 92%;
			border-radius: 10 * $mobile;
			padding: 30 * $mobile 12 * $mobile;
			box-shadow: 0 0 16 * $mobile $color-blue-zodiac-3;
		}
	}

	&__header {
		margin: 0 0 24px;
		font-size: 20px;
		line-height: 24px;
		text-transform: uppercase;
		text-align: center;
		color: $color-white;

		@include pc {
			margin: 0 0 24 * $pc;
			font-size: 20 * $pc;
			line-height: 24 * $pc;
		}

		@include tablet {
			margin: 0 0 24 * $tablet;
			font-size: 20 * $tablet;
			line-height: 24 * $tablet;
		}

		@include mobile {
			margin: 0 0 24 * $mobile;
			font-size: 20 * $mobile;
			line-height: 24 * $mobile;
		}
	}

	&__close {
		position: absolute;
		right: 8px;
		top: 8px;
		z-index: 1;
		width: 24px;
		height: 24px;
		cursor: pointer;

		@include pc {
			right: 8 * $pc;
			top: 8 * $pc;
			width: 22 * $pc;
			height: 22 * $pc;
		}

		@include tablet {
			right: 8 * $tablet;
			top: 8 * $tablet;
			width: 22 * $tablet;
			height: 22 * $tablet;
		}

		@include mobile {
			right: 8 * $mobile;
			top: 8 * $mobile;
			width: 22 * $mobile;
			height: 22 * $mobile;
		}
		
		&__inner {
			position: relative;
			width: 100%;
			height: 100%;
			background: rgba($color-black, 0.005);
		}

		svg {
			position: relative;
			width: 100%;
			height: 100%;
			fill: rgba($color-white, 0.15);
			z-index: -1;
			transition: all 0.3s;
		}

		&:hover svg {
			fill: $color-white;
		}
	}
}