.doctor-teaser {
	display: flex;
	align-items: center;
	text-decoration: none;
	transition: all 300ms linear;

	&:hover {
		opacity: 0.85;
	}

	&__photo {
		position: relative;
		margin-right: 15px;
		border-radius: 50%;
		width: 41px;
		height: 41px;

		@include pc {
			margin-right: 15 * $pc;
			width: 41 * $pc;
			height: 41 * $pc;
		}

		@include tablet {
			margin-right: 15 * $tablet;
			width: 41 * $tablet;
			height: 41 * $tablet;
		}

		@include mobile {
			margin-right: 15 * $mobile;
			width: 41 * $mobile;
			height: 41 * $mobile;
		}

		&::before {
			content: "";
			position: absolute;
			left: -4px;
			top: -4px;
			z-index: 1;
			border-radius: 50%;
			width: 49px;
			height: 49px;
			background: rgba($color-white, 0.2);

			.is-dark & {
				background: rgba($color-black, 0.05);
			}

			@include pc {
				left: -4 * $pc;
				top: -4 * $pc;
				width: 49 * $pc;
				height: 49 * $pc;
			}

			@include tablet {
				left: -4 * $tablet;
				top: -4 * $tablet;
				width: 49 * $tablet;
				height: 49 * $tablet;
			}

			@include mobile {
				left: -4 * $mobile;
				top: -4 * $mobile;
				width: 49 * $mobile;
				height: 49 * $mobile;
			}
		}

		&__wrapper {
			overflow: hidden;
			border-radius: 50%;
			width: 41px;
			height: 41px;
	
			@include pc {
				width: 41 * $pc;
				height: 41 * $pc;
			}
	
			@include tablet {
				width: 41 * $tablet;
				height: 41 * $tablet;
			}
	
			@include mobile {
				width: 41 * $mobile;
				height: 41 * $mobile;
			}
		}

		img {
			display: block;
			position: relative;
			z-index: 2;
			width: 100%;
		}
	}

	&__details {}

	&__text {
		margin-bottom: 4px;
		font-weight: 300;
		font-size: 14px;
		letter-spacing: 0.06px;
		line-height: 16px;
		color: rgba($color-white, 0.9);

		.is-dark & {
			color: rgba($color-black, 0.9)
		}

		@include pc {
			margin-bottom: 4 * $pc;
			font-size: 14 * $pc;
			letter-spacing: 0.06 * $pc;
			line-height: 16 * $pc;
		}

		@include tablet {
			margin-bottom: 4 * $tablet;
			font-size: 14 * $tablet;
			letter-spacing: 0.06 * $tablet;
			line-height: 16 * $tablet;
		}

		@include mobile {
			margin-bottom: 4 * $mobile;
			font-size: 14 * $mobile;
			letter-spacing: 0.06 * $mobile;
			line-height: 16 * $mobile;
		}
	}

	&__name {
		font-weight: 800;
		font-size: 16px;
		letter-spacing: 0.03px;
		line-height: 20px;
		color: $color-white;

		.is-dark & {
			color: rgba($color-black, 0.9)
		}

		@include pc {
			font-size: 16 * $pc;
			letter-spacing: 0.03 * $pc;
			line-height: 20 * $pc;
		}

		@include tablet {
			font-size: 16 * $tablet;
			letter-spacing: 0.03 * $tablet;
			line-height: 20 * $tablet;
		}

		@include mobile {
			font-size: 16 * $mobile;
			letter-spacing: 0.03 * $mobile;
			line-height: 20 * $mobile;
		}
	}

	&__more {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 12px;
		border-radius: 50%;
		width: 21px;
		height: 21px;
		background-color: rgba($color-black, 0.2);

		@include pc {
			margin-left: 12 * $pc;
			width: 21 * $pc;
			height: 21 * $pc;
		}

		@include tablet {
			margin-left: 12 * $tablet;
			width: 21 * $tablet;
			height: 21 * $tablet;
		}

		@include mobile {
			margin-left: 12 * $mobile;
			width: 21 * $mobile;
			height: 21 * $mobile;
		}

		svg {
			width: 7px;
			height: 6px;
			fill: $color-white;

			@include pc {
				width: 7 * $pc;
				height: 6 * $pc;
			}

			@include tablet {
				width: 7 * $tablet;
				height: 6 * $tablet;
			}

			@include mobile {
				width: 7 * $mobile;
				height: 6 * $mobile;
			}
		}
	}
}