.about {
	display: flex;
	justify-content: space-between;

	@include tablet {
		align-items: flex-end;
	}

	@include mobile {
		flex-direction: column;
	}

	&__description {
		max-width: percentage(350 / 1050);

		@include mobile {
			display: flex;
			flex-wrap: wrap;
			max-width: 100%;
		}
	}

	&__text {
		&, p {
			margin: 50px 0 40px;
			font-weight: 300;
			font-size: 14px;
			letter-spacing: 0.02px;
			line-height: 23px;
			color: $color-mine-shaft;

			@include pc {
				margin: 50 * $pc 0 40 * $pc;
				font-size: 14 * $pc;
				letter-spacing: 0.02 * $pc;
				line-height: 23 * $pc;
			}

			@include tablet {
				margin: 50 * $tablet 0 40 * $tablet;
				font-size: 14 * $tablet;
				letter-spacing: 0.02 * $tablet;
				line-height: 23 * $tablet;
			}

			@include mobile {
				margin: 0 0 12 * $mobile;
				font-size: 14 * $mobile;
				letter-spacing: 0.02 * $mobile;
				line-height: 23 * $mobile;
				max-width: 100%;
			}
		}

		p {
			margin: 0 0 16px;

			@include pc {
				margin: 0 0 16 * $pc;
			}

			@include tablet {
				margin: 0 0 16 * $tablet;
			}

			@include mobile {
				margin: 0 0 16 * $mobile;
			}
		}
	}

	&__photo {
		img {
			display: block;
			width: 100%;
		}

		@include mobile {
			margin: 0 0 16 * $mobile;
		}
	}

	&__slider {
		position: relative;
		width: percentage(630 / 1050);

		@include mobile {
			width: 100%;
		}
	}
}