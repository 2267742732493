.header {
	position: relative;
	z-index: 900;

	&.is-scrolling {
		z-index: inherit;
	}

	&.is-static {
		background-color: $color-madison-1;

		&::before {
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			width: percentage(435 / 1250);
			height: 100%;
			background-color: $color-white;
			transition: opacity 0.5s ease;

			@include mobile {
				display: none;
			}
		}
	}

	&__fixed {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 900;
		width: 100%;
		background: $color-madison;
		transform: translateY(-100%);
		transition: transform 0.3s linear;

		&.is-fixed,
		&.is-active {
			transform: translateY(0);
		}
	
		&.is-relative {
			position: relative;
			transform: translateY(0);
		}

		.header {
			&__inner {
				padding: 12px $gutter-md;

				.is-absolute & {
					position: relative;
				}

				@include pc {
					padding: 12 * $pc $gutter-lg * $pc;
				}

				@include tablet {
					padding: 12 * $tablet $gutter-lg * $tablet;
				}

				@include mobile {
					padding: 8 * $mobile 20 * $mobile;
				}
			}
		}
	}

	&__inner {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		padding: 25px $gutter-md;
		max-width: $container-md;

		.is-absolute & {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			width: 100%;
		}

		@include pc {
			padding: 25 * $pc $gutter-lg * $pc;
			max-width: $container-lg;
		}

		@include tablet {
			padding: 25 * $tablet $gutter-lg * $tablet;
			max-width: $container-lg;
		}

		@include mobile {
			padding: 12 * $mobile 20 * $mobile 20 * $mobile;
			max-width: $container-lg;
		}
	}

	&__logo {
		@include mobile {
			flex-grow: 1;

			.header__fixed & {
				flex-grow: 0;
			}
		}
	}

	&__location {
		margin-left: 37%;

		@include tablet {
			margin-left: 26%;
		}

		@include mobile {
			display: none;
		}

		.header__fixed &,
		.header--alt & {
			margin-left: 0;

			@include tablet {
				margin-left: 0;
			}

			@include mobile {
				display: none;
			}
		}
	}

	&__phone {
		margin-left: auto;
		margin-right: 42px;

		@include pc {
			margin-right: 42 * $pc;
		}

		@include tablet {
			margin-right: 42 * $tablet;
		}

		@include mobile {
			display: none;
		}

		.header__fixed & {
			margin: 0;
		}
	}

	&__nav,
	&__lang {
		@include mobile {
			display: none;
		}
	}

	&__action {
		@include mobile {
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 120 * $mobile;
		}
	}

	&__menu {
		display: none;

		@include mobile {
			display: block;
		}
	}
}