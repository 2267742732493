.pulsar {
	width: 24px;
	height: 24px;
	backface-visibility: hidden;

	@include pc {
		width: 24 * $pc;
		height: 24 * $pc;
	}

	@include tablet {
		width: 24 * $tablet;
		height: 24 * $tablet;
	}

	@include mobile {
		width: 24 * $mobile;
		height: 24 * $mobile;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		border-radius: 50%;
		border: 2px solid $color-red;
		box-shadow: inset 0 0 8px 0 $color-red;
		width: 100%;
		height: 100%;
		transform: scale(0);

		@include pc {
			border: 2 * $pc solid $color-red;
			box-shadow: inset 0 0 8 * $pc 0 $color-red;
		}

		@include tablet {
			border: 2 * $tablet solid $color-red;
			box-shadow: inset 0 0 8 * $tablet 0 $color-red;
		}

		@include mobile {
			border: 2 * $mobile solid $color-red;
			box-shadow: inset 0 0 8 * $mobile 0 $color-red;
		}
	}

	&::before {
		animation: pulsar 1.5s 0s linear infinite;
	}

	&::after {
		animation: pulsar 1.5s .75s linear infinite;
	}
}

@keyframes pulsar {
	0% {
			transform: scale(0);
			opacity: 1;
	}
	75% {
			opacity: 1;
	}
	100% {
			transform: scale(1);
			opacity: 0;
	}
}
