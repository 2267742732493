.exhortation {
	font-weight: 800;
	font-size: 40px;
	letter-spacing: 0.1px;
	line-height: 46px;
	color: $color-madison-3;

	@include pc {
		font-size: 40 * $pc;
		letter-spacing: 0.1 * $pc;
		line-height: 46 * $pc;
	}

	@include tablet {
		font-size: 40 * $tablet;
		letter-spacing: 0.1 * $tablet;
		line-height: 46 * $tablet;
	}

	@include mobile {
		font-size: 26 * $mobile;
		letter-spacing: 0.1 * $mobile;
		line-height: 34 * $mobile;
	}

	.is-green {
		color: $color-inch-worm;
	}

	.is-silver {
		color: $color-alto-1;
	}

	.is-bordered {
		color: $color-white;
		letter-spacing: 1px;
		text-shadow: $color-madison-3 0 0 1px, $color-madison-3 0 0 1px, $color-madison-3 0 0 1px, $color-madison-3 0 0 1px;
	}
}